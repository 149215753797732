import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ModTvSelect1View } from './ModTvSelect1View';
import { Typography, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { TvCourseModel } from '../../../../models/mypage/tv/TvCourseModel';
import { TvCourseListParts } from '../../parts/tv_course_list_parts/TvCourseListParts';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { WprSpan } from '../../../../../wpr-framework/component/controls/WprSpan';
import tvIcon from '/shared/image/common/logo_iTSCOMTV.png';
import helpOrangeIcon from '/shared/image/common/icon_Help_bgLigntOrange.png';
import nextOrangeIcon from '/public/next_icon_orange.png';
import styles from './ModTvSelect1.scss';
import warningIcon from './../../../../../../shared/image/common/important_yellow.png';


/**
 * テレビ変更選択画面用コンポーネント
 */
export class ModTvSelect1 extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ModTvSelect1View(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** テレビ変更選択画面ビュー情報 */
	public get viewInfo(): ModTvSelect1View	{ return this.view as ModTvSelect1View;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('BeforeTvOptionListModel', null, this.onTvOptionModelRender);
		this.addListRender('TvCourseModel', null, this.onTvCourseModelRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='sub-logo'>
						<img src= {tvIcon}></img>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
							</div>
						</div>
						<div className='link_blue'>
							<WprAnchor name='helpLink' view={this.view}>申込みについてご不明な方はこちら＞</WprAnchor>
						</div>
						<div className='title_space'>
							<div className='itemTitle title_maru'>基本情報</div>
						</div>
						<div className='constractTable'>
							<Grid container className='feeRow'>
								<Grid item xs={12} md={5} className='option' style={{padding: '1rem'}}>
									<MatTypography name='course' className='courseName' view={this.view}  style={{fontSize: '16px'}}/>
									{/* (月額
									<MatTypography name='amount' className='courseName' view={this.view} style={{fontSize: '16px'}}/>
									円) */}
								</Grid>
								<Grid item xs={12} md={7} className='optstatus' style={{padding: '1rem'}}>
									<MatTypography name='contractStatus' className='itemUnit_bold' view={this.view} style={{fontSize: '16px'}}/>
								</Grid>
							</Grid>	
						</div>
						<WprContainer mode='オプション.PC' view={this.view}>
							<div className='border_around'>
								<TableContainer>
									<Table>
										<colgroup>
											<col style={{width:'40%'}}/>
											<col style={{width:'35%'}}/>
											<col style={{width:'25%'}}/>
										</colgroup>
										<TableBody >
											<TableRow className='deviceRow'>
												<TableCell align='center' className='deviceTitle border_right firstTitle' style={{padding: '1.1rem'}}>
													<Typography variant='body2' className='font_bold'>A-CAS番号</Typography>
												</TableCell>
												<TableCell colSpan={2}>
													<MatTypography name='aCas' view={this.view}/>
												</TableCell>
											</TableRow>
											<TableRow className='deviceRow'>
												<TableCell align='center' className='deviceTitle border_right' style={{padding: '1.1rem'}}>
													<Typography variant='body2' className='font_bold'>B-CAS番号
														<a href='https://www.itscom.co.jp/support/mypage/setup/self_confirm/#hdg2' target='_blank'><img src={helpOrangeIcon}  alt='' style={{marginLeft: '3px'}}></img></a>
													</Typography>
												</TableCell>
												<TableCell colSpan={2}>
													<MatTypography name='bCas' view={this.view}/>
												</TableCell>
											</TableRow>
											<TableRow className='deviceRow'>
												<TableCell align='center' className='deviceTitle border_right' style={{padding: '1.1rem'}}>
													<Typography variant='body2' className='font_bold'>C-CAS番号
													<a href='https://www.itscom.co.jp/support/mypage/setup/self_confirm/#hdg2' target='_blank'><img src={helpOrangeIcon} alt='' style={{marginLeft: '3px'}}></img></a>
													</Typography>
												</TableCell>
												<TableCell colSpan={2}>
													<MatTypography name='cCas' view={this.view}/>
												</TableCell>
											</TableRow>
											<TableRow className='deviceRow'>
												<TableCell align='center' className='deviceTitle border_right' style={{padding: '1.1rem'}}>
													<Typography variant='body2' className='font_bold'>端末タイプ</Typography>
												</TableCell> 
												<TableCell colSpan={2}>
													<WprSpan name='terminal' view={this.view}/> 
													<span>　</span>
													<WprSpan name='applyType' view={this.view}/>
												</TableCell>
											</TableRow>
											<TableRow className='deviceRow'>
												<TableCell align='center' className='deviceTitle border_right' style={{padding: '1.1rem'}}>
													<Typography variant='body2' className='font_bold'>iTSCOM magazine</Typography>
												</TableCell>
												<TableCell colSpan={2}>
													<MatTypography name='magazine' view={this.view}/>
												</TableCell>
											</TableRow>
											<TableRow className='deviceRow last'>
												<TableCell align='center' rowSpan={this.state.data.sentenceData} className='deviceTitle border_right lastTitle' style={{padding: '1.1rem'}}>
													<Typography variant='body2' className='font_bold'>オプションチャンネル</Typography>
												</TableCell>
											</TableRow>
											{this.renderList('BeforeTvOptionListModel')}
										</TableBody>
									</Table>
								</TableContainer>
							</div>
						</WprContainer>
						{/* SP用 */}
						<WprContainer mode='オプション.SP' view={this.view}>
							<TableContainer>
								<Table className='tableSP'>
									<colgroup>
										<col style={{width:'70%'}}/>
										<col style={{width:'30%'}}/>
									</colgroup>
									<TableBody >
										<TableRow className='deviceRow'>
											<TableCell className='deviceTitle firstTitle' colSpan={2} style={{backgroundColor: '#F5F5FA', borderRadius: '12px 12px 0 0', padding: '10px'}}>
												<Typography variant='body2' className='font_bold'>A-CAS番号</Typography>
											</TableCell>
										</TableRow>
										<TableRow className='deviceRow'>
											<TableCell colSpan={2}>
												<MatTypography name='aCas' view={this.view}/>
											</TableCell>
										</TableRow>
										<TableRow className='deviceRow'>
											<TableCell className='deviceTitle' colSpan={2} style={{backgroundColor: '#F5F5FA', padding: '10px'}}>
												<Typography variant='body2' className='font_bold'>B-CAS番号
													<a href='https://www.itscom.co.jp/support/mypage/setup/self_confirm/#hdg2' target='_blank'><img src={helpOrangeIcon}  alt='' style={{marginLeft: '3px'}}></img></a>
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow className='deviceRow'>
											<TableCell colSpan={2}>
												<MatTypography name='bCas' view={this.view}/>
											</TableCell>
										</TableRow>
										<TableRow className='deviceRow'>
											<TableCell className='deviceTitle' colSpan={2} style={{backgroundColor: '#F5F5FA', padding: '10px'}}>
												<Typography variant='body2' className='font_bold'>C-CAS番号
												<a href='https://www.itscom.co.jp/support/mypage/setup/self_confirm/#hdg2' target='_blank'><img src={helpOrangeIcon} alt='' style={{marginLeft: '3px'}}></img></a>
												</Typography>
											</TableCell>
										</TableRow>
										<TableRow className='deviceRow'>
											<TableCell colSpan={2}>
												<MatTypography name='cCas' view={this.view}/>
											</TableCell>
										</TableRow>
										<TableRow className='deviceRow'>
											<TableCell className='deviceTitle' colSpan={2} style={{backgroundColor: '#F5F5FA', padding: '10px'}}>
												<Typography variant='body2' className='font_bold'>端末タイプ</Typography>
											</TableCell> 
										</TableRow>
										<TableRow className='deviceRow'>
											<TableCell colSpan={2}>
												<WprSpan name='terminal' view={this.view}/> 
												<span>　</span>
												<WprSpan name='applyType' view={this.view}/>
											</TableCell>
										</TableRow>
										<TableRow className='deviceRow'>
											<TableCell className='deviceTitle' colSpan={2} style={{backgroundColor: '#F5F5FA', padding: '10px'}}>
												<Typography variant='body2' className='font_bold'>iTSCOM magazine</Typography>
											</TableCell>
										</TableRow>
										<TableRow className='deviceRow'>
											<TableCell colSpan={2}>
												<MatTypography name='magazine' view={this.view}/>
											</TableCell>
										</TableRow>
										<TableRow className='deviceRow last'>
											<TableCell className='deviceTitle lastTitle' colSpan={2} style={{backgroundColor: '#F5F5FA', padding: '10px'}}>
												<Typography variant='body2' className='font_bold'>オプションチャンネル</Typography>
											</TableCell>
										</TableRow>
										<WprContainer mode={'変更前オプション.なし'} view={this.view}>
											<TableRow className='deviceRow'>
												<TableCell style={{borderBottom: 'none', padding: '25px'}}></TableCell>
												<TableCell style={{borderBottom: 'none'}}></TableCell>
											</TableRow>
										</WprContainer>
										{this.renderList('BeforeTvOptionListModel')}
									</TableBody>
								</Table>
							</TableContainer>
						</WprContainer>
						<div className='title_space'>
							<div className='itemTitle title_maru'>申込み内容</div>
						</div>
						<div style={{textAlign:'right'}}>
							<a href="https://www.itscom.co.jp/service/cabletv/course/" target="_brank" style={{color:'black' ,textDecoration:'none'}}>
								<b>視聴可能なチャンネル一覧 </b>
								<img src={nextOrangeIcon} style={{width:'20px',verticalAlign:'bottom'}}/>
							</a>
						</div>
							{this.renderList('TvCourseModel')}
						<div style = {{marginBottom: '50px'}}>
							<ul className='ul_kome attention_fontSize' style={{marginBottom: '0'}}>
								<li>
									お客さまのご住所及び建物の設備状況によっては、サービスをご利用になれない場合や、ご覧になれないチャンネルや機能があります。
								</li>
							</ul>
							<ul className='ul_none attention_fontSize' style={{marginBottom: '0', marginTop: '0'}}>
								<WprContainer mode='お得パックエリア.表示' view={this.view}>
									<li>
										契約期間中にお得パック対象サービスの一部または全部をコース変更または解約する場合、契約違約金がかかります（更新月を除く）。
									</li>
								</WprContainer>
							</ul>
							<ul className='ul_kome attention_fontSize' style={{marginTop: '0'}}>
								<WprContainer mode='コース警告.表示' view={this.view}>
									<li>
										<MatTypography name={'courseMessage'} view={this.view} style={{display: 'contents', fontSize: '12px', fontWeight: '500'}}></MatTypography>
										は、600メガコースを含む2年コースまたは光お得パックをご契約の場合にお申し込みいただけます。
									</li>
								</WprContainer>
								<WprContainer mode='plus警告.表示' view={this.view}>
									<li>
										マックスplus・スタンダードplus・ミニplusは、未成年のお客さまはお申し込みいただけません。
									</li>
								</WprContainer>
								<li>
									お得パックご利用中のお客さまにおかれましては、お得パックの内容を組みかえて、契約変更させていただきます。<br/>その際、お得パックの利用期間は、組みかえさせていただきました翌月からの起算に変更となりますので、ご了承ください。 なお、利用機器に変更が必要な場合や、別途お得パックの適用が外れる場合など、確認事項が発生いたしました際には、別途ご連絡いたします。
								</li>
							</ul>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								次へ
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>


				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=BeforeTvOptionListModel
	 */
	public onTvOptionModelRender(rowInfo: WprRowInfo): any {
		return (
			<TableRow className='deviceRow'>
				<TableCell className='border_right'>
					<MatTypography name='option' row={rowInfo} view={this.view} style={{ fontSize: '15px'}}/>
				</TableCell>
				<TableCell align='center'>
					<MatTypography name='status' row={rowInfo} view={this.view} style={{ fontSize: '15px'}}/>
				</TableCell>
			</TableRow>
		);
	}
	/**
	 * リスト名=TvCourseModel
	 */
	public onTvCourseModelRender(rowInfo: WprRowInfo): any {
		const rowData: TvCourseModel =rowInfo.rowData;
		const courseCd: string = rowData.course;
		return (
			<Grid item xs={12}>
				<TvCourseListParts row={rowInfo} view={this.view} name={courseCd}/>
			</Grid>
		);
	}
	// --------------------------------------------------------------------------
}
