import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { ModTvequipSelectView } from './ModTvequipSelectView';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import tvIcon from '/shared/image/common/logo_iTSCOMTV.png';
import { Grid, TableContainer, Table, TableBody, TableRow, TableCell, Typography, Divider } from '@material-ui/core';
import { WprAnchor } from '../../../../../wpr-framework/component/controls/WprAnchor';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { WprSpan } from '../../../../../wpr-framework/component/controls/WprSpan';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import helpOrangeIcon from '/shared/image/common/icon_Help_bgLigntOrange.png';
import { MatCheckBox } from '../../../../../wpr-framework/component/controls/material/MatCheckBox';
import styles from './ModTvequipSelect.scss';
import { MatDatePicker } from '../../../../../wpr-framework/component/controls/material/MatDatePicker';
import { MatSelect } from '../../../../../wpr-framework/component/controls/material/MatSelect';
import { WprViewError } from '../../../../../wpr-framework/component/controls/WprViewError';
import { WprErrorContainer } from '../../../../../wpr-framework/component/controls/WprErrorContainer';
import errorIcon from '../../../../../../shared/image/common/icon_error.png'
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { MatTextField } from '../../../../../wpr-framework/component/controls/material/MatTextField';

/**
 * 機器変更選択用コンポーネント
 */
export class ModTvequipSelect extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new ModTvequipSelectView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 機器変更選択ビュー情報 */
	public get viewInfo(): ModTvequipSelectView	{ return this.view as ModTvequipSelectView;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='sub-logo'>
						<img src= {tvIcon}></img>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
								<Typography className='title_comment'>以下の内容でよろしければ、「申込む」ボタンをクリックしてください。</Typography>
							</div>
						</div>
						<WprContainer mode='エラー.表示' view={this.view}>
							<div className='errorArea'>
								<span className='parallel'>
									<img src={errorIcon} alt='' className='x_img'/>
									<div className='errorList'>
										{this.renderList('ErrorModel')}
									</div>
								</span>
							</div>
						</WprContainer>
						<div className='feeTable'>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>機器の種類<a href='https://www.itscom.co.jp/service/cabletv/equipment/' target='_blank'><img src={helpOrangeIcon}  alt='' style={{marginLeft: '3px'}}></img></a></div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal'>
									<WprContainer mode={'区分.FTTH'} view={this.view}>
										<MatCheckBox name='hitpot' color='primary' className='checkbox_size' view={this.view} /><span  className='itemUnit' style={{ color: '#484848' }}>HitPot</span>
									</WprContainer>
									<WprContainer mode={'区分.FTTH以外'} view={this.view}>
										<MatCheckBox name='bdHitpot' color='primary' className='checkbox_size' view={this.view} /><span className='itemUnit' style={{ color: '#484848' }}>BD-HitPot</span>
										<MatCheckBox name='hitpot' color='primary' className='checkbox_size' view={this.view} /><span className='itemUnit' style={{ color: '#484848' }}>HitPot</span>
										<MatCheckBox name='stb' color='primary' className='checkbox_size' view={this.view} /><span className='itemUnit' style={{ color: '#484848' }}>STB</span>
									</WprContainer>
								</Grid>
							</Grid>	
							<Grid container className='feeRow'>
								<Grid item xs={12} md={4} className='feeTitle'>
									<div>機器のご提供方法</div>
								</Grid>
								<Grid item xs={12} md={8} className='feeVal'>
									<MatCheckBox name='rental' color='primary' className='checkbox_size' view={this.view} /><span className='itemUnit' style={{ color: '#484848' }}>レンタル</span>
								</Grid>
							</Grid>
						</div>
						<WprContainer mode={'区分.FTTH以外'} view={this.view}>
							<div className='gray'>
								既に購入済みのお客さまはレンタルへの変更はできません。<br/>レンタルから購入に変更される場合は新しい機器をお取り付けいたします。
							</div>
						</WprContainer>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								次へ
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>
				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
}
