import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { UsageDetailsService } from '../../../service/UsageDetailsService';
import { GetUsageDetailsRequest } from '../../../service/models/dto/usagedetails/GetUsageDetailsRequest';
import { WprListData } from '../../../../wpr-framework/view/deco/WprListData';
import { UsageServiceInfo } from '../../../models/UsageServiceInfo';
import { UsageMonthlyInfo } from '../../../models/UsageMonthlyInfo';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import { WprDI_Object } from '../../../../wpr-framework/view/di/WprDI_Object';
import { UsagePDFReceiveData } from '../dialog/usage_p_d_f_download_dialog/UsagePDFDownloadDialogView';
import { UsageGraphInfo } from '../../../models/UsageGraphInfo';
import { GetUsageDetailsYearRequest } from '../../../service/models/dto/usagedetails/GetUsageDetailsYearRequest';
import { TokyuServiceInfo } from '../../../models/TokyuServiceInfo';
import { TokyuGraphReceiveData } from '../dialog/usage_toukyu_graph_dialog/UsageToukyuGraphDialogView';
import { Customer } from '../../../models/bss/customer/Customer';
import { UsageAddressInfo } from '../../../models/UsageAddressInfo';
import { objectSize } from 'pdfjs-dist/types/src/shared/util';

/**
 * 利用明細：利用料金タブ
 */
 export class UsageFeeTabView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('UsageFeeTab'); }
	// --------------------------------------------------------------------------
	
	// モデル  ------------------------------------------------------------------
	@WprDI_Object
	@WprListData('UsageMonthlyInfoList')
	private m_UsageMonthlyInfoList: UsageMonthlyInfo[]	= null;	// 利用明細情報（月単位）リスト（このリストの分だけタブを表示する)	
	@WprDI_Object
	@WprListData('EnergyMonthList')
	private m_energyMonthList: string[]	= null;	// でんきガス一覧用年月リスト
	@WprModel('UsageMonthlyInfo')
	private m_UsageMonthlyInfo: UsageMonthlyInfo	= null;	// 利用明細情報（月単位）
	@WprModel('UsageGraphInfo')
	private m_UsageGraphInfo: UsageGraphInfo	= null;	// 利用明細グラフ情報
	@WprListData('UsageServiceInfoList')
	private m_UsageServiceInfoList: UsageServiceInfo[]	= null;	// サービスの明細情報リスト
	@WprListData('TokyuServiceInfoList')
	private m_TokyuServiceInfoList: TokyuServiceInfo[]	= null;	// 東急サービスの明細情報リスト
	@WprListData('InvoiceStatementList')
	private m_InvoiceStatementList: UsageServiceInfo[]	= null;	// インボイス対象明細情報
	@WprModel('TokyuTotal')
	private m_TokyuTotal: number	= 0;	// 東急でんき＆ガス請求額合計
	@WprModel('CustomerInfo')
	private m_CustomerInfo: Customer	= null;	// 顧客情報
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_UsageDetailsService: UsageDetailsService	= new UsageDetailsService();	// 利用明細サービス
	// --------------------------------------------------------------------------

	// private変数  --------------------------------------------------------------
	public m_CablePlusGraphPeriod: string	= null;	// ケーブルプラス電話明細グラフの表示期間
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		// 対象月
		this.addConfig('month', '対象月', 'UsageMonthlyInfo', { converter: '年月（yyyy年MM月）' });
		// 請求合計
		this.addConfig('total', '請求合計', 'UsageMonthlyInfo', { bindName: 'total', converter: '3桁カンマ' });
		// ご契約情報
		this.addConfig('contractName', '契約者氏名', 'UsageMonthlyInfo.usageAddressInfo');
		this.addConfig('contractAddress', '契約者住所', 'UsageMonthlyInfo.usageAddressInfo');
		// ご請求情報
		this.addConfig('billingName', '契約者氏名', 'UsageMonthlyInfo.usageAddressInfo');
		this.addConfig('billingAddress', '契約者住所', 'UsageMonthlyInfo.usageAddressInfo');

		// 請求情報

		// サービス単位の請求明細リスト
		this.addListConfig('serviceCategory', 'サービスカテゴリ', 'UsageServiceInfoList', { converter: '利用明細ヘッダー' });
		this.addListConfig('taxIn', '金額合計', 'UsageServiceInfoList', {converter: '3桁カンマ'});

		this.addListConfig('invoiceServiceCategory', 'サービスカテゴリ', 'InvoiceStatementList', {bindName: 'serviceCategory'});
		this.addListConfig('invoiceTaxIn', '金額合計', 'InvoiceStatementList', { bindName: 'taxIn',converter: '3桁カンマ'});

		// でんき＆ガス
		this.addConfig('tokyuTotalTaxIn', '東急でんき＆ガス請求額合計', 'TokyuTotal', { bindModel: true, converter: '3桁カンマ' });
		// 東急
		this.addListConfig('tokyuServiceCategory', 'サービス名称', 'TokyuServiceInfoList', { bindName: 'serviceCategory' });
		this.addListConfig('tokyuTaxIn', '金額（税込み）', 'TokyuServiceInfoList', { bindName: 'taxIn', converter: '3桁カンマ' });
		this.addListConfig('tokyuSupplyPointNum', '供給地点特定番号', 'TokyuServiceInfoList', { bindName: 'supplyPointNum' });
		this.addListConfig('tokyuContractPlan', '契約プラン', 'TokyuServiceInfoList', { bindName: 'contractPlan' });
		this.addListConfig('tokyuUsagePeriod', '利用期間', 'TokyuServiceInfoList', { bindName: 'fromMonth', converter: '年月（yyyy年MM月）' });
		this.addListConfig('tokyuUsageFrom', '利用開始日', 'TokyuServiceInfoList', { bindName: 'fromMonth', converter: '日付（yyyy/MM/dd）' });
		this.addListConfig('tokyuUsageTo', '利用終了日', 'TokyuServiceInfoList', { bindName: 'toMonth', converter: '日付（yyyy/MM/dd）' });
		this.addListConfig('tokyuConsumption', '使用量', 'TokyuServiceInfoList', { bindName: 'consumption' });
		this.addListConfig('tokyuConsumptionUnit', '使用量単位', 'TokyuServiceInfoList', { bindName: 'consumptionUnit' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('pdfDownload', this.onPdfDownload);
		this.addAction('graph', this.onGraph);
		this.addAction('toukyuGraph', this.onToukyuGraph);
		this.addAction('usageInfoLink', this.onUsageInfoLink);
		this.addAction('tokyuLink', this.onTokyuLink);
		this.addAction('mypageSmahoLink', this.onMypageSmahoLink);
		this.addAction('mobileDataLink', this.onMobileDataLink);
		this.addAction('otherInquiryLink', this.onOtherInquiryLink);
		this.addAction('invoiceFaqLink', this.onInvoiceFaqLink);
	}

	/**
	 * WprDI_Objectの設定通知
	 * @param name WprDI_Objectメンバ名
	 * @param obj 設定値
	 */
	public onSetDIObject(name: string, obj: any) {
		this.m_UsageMonthlyInfo = this.props.row.rowData;	// 親で選択された月明細
		if (ObjectUtil.isNullOrEmptyArray(this.m_UsageMonthlyInfo.serviceInfoList)) {
			// 未取得の場合はAPIへ（取得済みの場合はここに入らない）
			let req: GetUsageDetailsRequest = new GetUsageDetailsRequest();
			req.customerId = this.m_UsageMonthlyInfo.customerId;
			req.month = this.m_UsageMonthlyInfo.month;
			// 選択された月の明細を取得する
			this.m_UsageDetailsService.getDetailMonth(this, req, (result) => {
				if (result) {
					if (ObjectUtil.isNotNullOrUndefined(result.statementModel)) {
						let serviceList: UsageServiceInfo[] = new Array();
						if (ObjectUtil.isNotNullOrEmptyArray(result.statementModel.usageServiceInfoList)) {
							result.statementModel.usageServiceInfoList.forEach((value) => {
								if (ObjectUtil.isNotNullOrEmptyStr(value.serviceCategory)) {
									serviceList.push(value);
								}
							});
						}
						this.setListData('UsageServiceInfoList', serviceList);
						this.setViewData('usageServiceInfoList', this.getUsageServiceInfoList());
					}
					if (ObjectUtil.isNotNullOrUndefined(result.toukyuStatementModel) && ObjectUtil.isNotNullOrUndefined(result.toukyuStatementModel.tokyuInfoList)) {
						this.m_TokyuTotal = result.toukyuStatementModel.totalTaxIn;
						this.setListData('TokyuServiceInfoList', result.toukyuStatementModel.tokyuInfoList);
						this.setViewData('TokyuServiceInfoList', this.getTokyuServiceInfoList());
					}
					if (ObjectUtil.isNotNullOrEmptyArray(result.invoiceStatementList)) {
						// 初期表示：最新の料金明細
						this.setViewMode('インボイス', '有');
						this.setListData('InvoiceStatementList', result.invoiceStatementList);
						this.setViewData('InvoiceStatementList', this.getInvoiceStatementList());
					} else {
						// 請求データ0件
						this.setViewMode('インボイス', '無');
					}
					this.m_UsageMonthlyInfo.total = result.statementModel.totalTaxIn;

					// 丸橋仮実装
					this.m_UsageMonthlyInfo.usageAddressInfo = result.usageAddressInfo;

					// 合算対応 - メッセージ表示モード設定
					this.setViewMode('合算', result.gassanFlg ? '有' : '');

					this.refreshView();
				}
			});
			this.getGraphShowMonthList();
		} else {
			this.setListData('UsageServiceInfoList', this.m_UsageMonthlyInfo.serviceInfoList);
			this.setViewData('usageServiceInfoList', this.getUsageServiceInfoList());
		}
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * PDFダウンロード
	 */
	public onPdfDownload(param: any): void {
		let data: UsagePDFReceiveData= new UsagePDFReceiveData();
		data.customerId = this.m_UsageMonthlyInfo.customerId;
		data.customerName = this.m_UsageMonthlyInfo.customerName;
		let targetMonthList: string[] = new Array();
		this.m_UsageMonthlyInfoList.forEach((value) => {
			targetMonthList.push(value.month);
		});
		data.monthList = targetMonthList;
		data.selectedIdx = this.m_UsageMonthlyInfo.monthIdx;
		this.showDialog('PDFダウンロード', data, (status, result) => {
			if (status === true) {
			}
		});
	}

	/**
	 * ケーブルプラス電話：グラフ表示
	 * ※遷移先で取得だとグラフの初期表示がうまくいかないためここで取得→DI　する
	 * そのため、ダイアログに渡すデータはnull
	 */
	public onGraph(param: any): void {
		let req: GetUsageDetailsYearRequest = new GetUsageDetailsYearRequest();
		req.customerId = this.m_UsageMonthlyInfo.customerId;
		req.name = 'ケーブルプラス電話';
		req.monthList = this.getGraphShowMonthList();
		this.m_UsageServiceInfoList.forEach(value => {
			if (value.serviceCategory === 'ケーブルプラス電話') {
				req.lineNo = value.courseInfoList[0].phoneNumber;	//取得する明細の電話番号（デフォルト）
			}
		});
		this.m_UsageDetailsService.getDetailYear(this, req, (result) => {
			if (result) {
				let monthList: string[] = new Array();
				let callChargeList: number[] = new Array();
				let basicChargeList: number[] = new Array();
				let otherChargeList: number[] = new Array();
				let totalChargeList: number[] = new Array();
				result.cPlusFeeList.forEach((value) => {
					monthList.push(value.month);
					callChargeList.push(value.callCharge);
					basicChargeList.push(value.basicCharge);
					otherChargeList.push(value.otherCharge);
					totalChargeList.push(value.totalCharge);
				});
				const diInfo: UsageGraphInfo = new UsageGraphInfo();
				diInfo.customerId = this.m_UsageMonthlyInfo.customerId;
				diInfo.monthList = monthList;
				// 電話番号のコンボボックス用
				let phoneNumberList: string[] = new Array();
				this.m_UsageServiceInfoList.forEach(value => {
					if (value.serviceCategory === 'ケーブルプラス電話') {
						value.courseInfoList.forEach(value => {
							if (!phoneNumberList.includes(value.phoneNumber)) {
								phoneNumberList.push(value.phoneNumber);
							}
						});
					}
				});
				diInfo.callChargeList = callChargeList;
				diInfo.basicChargeList = basicChargeList;
				diInfo.otherChargeList = otherChargeList;
				diInfo.totalChargeList = totalChargeList;
				diInfo.phoneNumberList = phoneNumberList;
				this.m_UsageGraphInfo = diInfo;	// DI
				this.showDialog('グラフ', null, (status, result) => {
					// 何もしない
				});
			}
		});
	}

	/**
	 * 東急でんき＆ガス：グラフ表示
	 */
	public onToukyuGraph(param: any): void {
		let sendData: TokyuGraphReceiveData = new TokyuGraphReceiveData();
		sendData.customerId = this.m_UsageMonthlyInfo.customerId;
		let targetMonthList: string[] = new Array();
		this.m_UsageMonthlyInfoList.forEach((value) => {
			targetMonthList.push(value.month);
		});
		let targetIndex: number = 0;
		this.m_energyMonthList.forEach((value, index) => {
			if (this.m_UsageMonthlyInfo.month === value) {
				targetIndex = index;
			} 
		});
		if (targetIndex !== 0) {
			sendData.selectedIdx = targetIndex;
		} else {
			sendData.selectedIdx = 0;
		}
		sendData.energyMonthList = this.m_energyMonthList;
		sendData.monthList = targetMonthList;
		// sendData.selectedIdx = this.m_UsageMonthlyInfo.monthIdx;

		this.showDialog('東急グラフ', sendData);
	}

	/**
	 * 利用明細・請求に関するご案内はこちら
	 */
	public onUsageInfoLink(param: any): void {
		window.open('https://www.itscom.co.jp/support/contract_change/billing/');
	}

	/**
	 * 東急ガスの請求について詳しく見る
	 */
	public onTokyuLink(param: any): void {
		window.open('https://mypage.tokyu-ps.jp/member/');
	}

	/**
	 * イッツコムスマホ　マイページ
	 */
	public onMypageSmahoLink(param: any): void {
		window.open('https://mymvno.jp/itscom/mypage/login');
	}

	/**
	 * データ通信量の確認方法について詳しく見る
	 */
	public onMobileDataLink(param: any): void {
		window.open('https://faq.itscom.net/faq/show/505?site_domain=default');
	}

	/**
	 * 明細に関するご質問はこちら
	 */
	public onOtherInquiryLink(param: any): void {
		location.href = this.getOldUrl() + "Inquiry/Inquiry_othe_entry/";
	}

	/**
	 * インボイスFAQリンク
	 */
	public onInvoiceFaqLink(param: any): void {
		window.open('https://faq.itscom.net/faq/show/37725?site_domain=default');
	}

	/**
	 * getter
	 * @returns 
	 */
	public getUsageServiceInfoList(): any {
		return this.m_UsageServiceInfoList;
	}
	public getTokyuServiceInfoList(): any {
		return this.m_TokyuServiceInfoList;
	}
	public getInvoiceStatementList(): any {
		return this.m_InvoiceStatementList;
	}

	// --------------------------------------------------------------------------

	// privateメソッド -----------------------------------------------------------
	/**
	 * グラフ表示用：
	 * 表示中の年月から過去１年分のYYYYMMリスト作成
	 * @param 選択中の年月
	 * @returns 
	 */
	private getGraphShowMonthList(): string[] {
		let list: string[] = [];
		// 選択中の月
		const selectedYear = parseInt(this.m_UsageMonthlyInfo.month.substring(0,4)); // yyyy部
		const selectedMonth = parseInt(this.m_UsageMonthlyInfo.month.substring(4,6)); // MM部
		for (let idx = 1; idx < 13; idx++) {	// 過去１年分繰り返す
			const date = new Date(selectedYear, selectedMonth -idx);
			const yearStr: string = date.getFullYear().toString();
			const monthStr: string = (date.getMonth() + 1).toString().padStart(2, '0');
			list.push(yearStr + monthStr);
		}
		// グラフダイアログのサブタイトル用にfrom~to月を取得
		let toMonth: string = list[0].substring(4,6) + '月';
		if (toMonth.charAt(0) === '0') {
			toMonth = toMonth.substring(1);
		}
		let toYearMonth = list[0].substring(0,4) + '年' + toMonth;
		let fromMonth: string = list[11].substring(4,6) + '月';
		if (fromMonth.charAt(0) === '0') {
			fromMonth = fromMonth.substring(1);
		}
		let fromYearMonth = list[11].substring(0,4) + '年' + fromMonth;
		this.m_CablePlusGraphPeriod = fromYearMonth + '～' + toYearMonth;
		return list.reverse();	// 旧→新順に並び替え
	}

	/**
	 * 契約情報と請求情報の設定
	 */
	private setContractBilling(customer: Customer) {
		// 契約情報
		if (customer.address && customer.address.length > 0) {
			let contractInfo = new UsageAddressInfo;
			// contractInfo.name = this.setName(customer);
			// contractInfo.address1 = this.setZipCd(customer.address[0].zipCD);
			// contractInfo.address2 = customer.address[0].addressLine1 + customer.address[0].addressLine2;
			// contractInfo.address3  = customer.address[0].addressLine3 + customer.address[0].roomNumber;
			// contractInfo.name = this.setName(customer);
			// contractInfo.address1 = '〒227-0062　神奈川県横浜市青葉区青葉台１－１－１２３　検証物件　１０１';
			// this.m_UsageMonthlyInfo.contractInfo = contractInfo;
		}
		// 請求情報（※ BSSの準備が整い次第）	
	}

	/**
	 * 郵便番号成形
	 */
	// private setZipCd(normalZipCd: string): string {
	// 	let start = normalZipCd.slice(0, 3);
	// 	let end = normalZipCd.slice(3);
	// 	let zipCd = '〒' + start + '-' + end;
	// 	return zipCd;
	// }

	/**
	 * 氏名
	 */
	// private setName(customer: Customer): string {
	// 	let name: string;
	// 	if (ObjectUtil.isNullOrEmptyStr(customer.sexCD) || customer.sexCD === '3')
	// 		name = customer.corporateName + '　様';
	// 	else
	// 		name = customer.surName + customer.givenName + '　様';
	// 	return name;
	// }

	
	// --------------------------------------------------------------------------

}
