import { MypageBaseView } from '../../base/MypageBaseView';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { CompleteModel } from '../../../view/common/common_complete/models/CompleteModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import { ConstrBaseModel } from '../../../models/mypage/Construction/ConstrBaseModel';

/**
 * 完了画面
 */
export class CommonCompleteView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('CommonCompleteView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprModel('CompleteModel')
	private m_CompleteModel: CompleteModel	= null;	// 完了情報モデル
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	// --------------------------------------------------------------------------
	
	// メンバ変数 ---------------------------------------------------------------
	private m_CustomerName: string							= null;	// 顧客名
	private m_PathName: string								= null;	// パス名
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('title_complete', 'タイトル', 'CompleteModel', { bindName: 'title' });
		this.addConfig('user', 'ユーザー', 'CompleteModel', { bindName: 'user' });
		this.addConfig('msg1', '本文1', 'CompleteModel', { bindName: 'massage1' });
		this.addConfig('bikou1', '備考1', 'CompleteModel', { bindName: 'bikou1' });
		this.addConfig('msg2', '本文2', 'CompleteModel', { bindName: 'massage2' });
		this.addConfig('bikou2', '備考2', 'CompleteModel', { bindName: 'bikou2' });
		this.addConfig('msg3', '本文3', 'CompleteModel', { bindName: 'massage3' });
		this.addConfig('msg4', '本文4', 'CompleteModel', { bindName: 'massage4' });
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('toQuestionnaire', this.onToQuestionnaire);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const customerInfo = this.m_MypageWizardMngr.getViewInfo().customer;
			if (customerInfo.sexCD === '3' || customerInfo.sexCD === '')
				this.m_CustomerName = customerInfo.corporateName;
			else
				this.m_CustomerName = customerInfo.surName + ' ' + customerInfo.givenName;
			const url = (window.location.href).split('/');
			const last = url.slice(-1)[0];
			this.m_PathName = last;
			this.setData(last);
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * アンケート
	 */
	public onToQuestionnaire(param: any): void {
		window.open('https://questant.jp/q/B7KS5E4S?id=' + this.m_MypageWizardMngr.getParams().customerId);
	}

	/**
	 * 契約内容の確認・変更メニューへ
	 */
	public onBack(param: any): void {
		if (this.m_PathName === 'constr_complete' || this.m_PathName === 'constr_change_complete' || this.m_PathName === 'constr_cancel_complete') {
			location.href = this.getOldUrl() + 'Top/My_top/';
		} else if (this.m_PathName === 'terminal_regist_complete') {
			location.href = this.getOldUrl() + 'Top/My_top/' + '?terminal_regist=successful';
		} else {
			location.href = this.getOldUrl() + 'Srvlist/Continfo_list/';
		}
	}
	// --------------------------------------------------------------------------
	
	// privateメソッド -----------------------------------------------------------
	/**
	 * 文章セット
	 */
	private setData(type: string) {
		this.setViewMode('遷移先', '一覧');
		const model = new CompleteModel();
		if (type === 'video_unlimited_complete') {
			model.title = '動画配信サービス 見放題パックプライム申込完了';
			model.massage1 = '見放題パックプライム申込みを受け付けました。<br/>ご連絡は「お客さま情報の確認・変更」に登録されている連絡先メールアドレス／電話番号にいたします。';
			model.bikou1 = '※連絡先メールアドレスに確認メールを送信しました。';
		}
		else if (type === 'video_ipstb_complete') {
			model.title = '動画配信サービス IP-STB申込完了';
			model.massage1 = 'IP-STBの申込みを受け付けました。<br/>ご連絡は「お客さま情報の確認・変更」に登録されている連絡先メールアドレス／電話番号にいたします。';
			model.bikou1 = '※連絡先メールアドレスに確認メールを送信しました。';
		}
		else if (type === 'add_tv_complete') {
			model.title = 'お申込みありがとうございます！';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = 'iTSCOM TVをお申込みいただき、誠にありがとうございます。<br/>コースの追加申込みを受け付けました。<br/>弊社担当より数日中にご連絡いたします。';
			model.bikou1 = '※ご連絡はお客さま情報の確認・変更に登録されている<br/>連絡先メールアドレス/電話番号にいたします。';
		}
		else if (type === 'itscom_home_complete') {
			model.user = this.m_CustomerName + 'さん';
			model.title = 'お申込みありがとうございます！';
			model.massage1 = 'コースの変更申込みを受け付けました。<br/>内容を確認させていただき、担当部門よりご連絡申し上げます。';
			model.bikou1 = '※ご連絡は「お客さま情報・変更」に登録されている<br/>連絡先メールアドレス/電話番号にいたします。';
			model.massage2 = '連絡先メールアドレスに確認メールを送信しました。';
		}
		else if (type === 'itscom_home_revise_complete') {
			model.user = this.m_CustomerName + 'さん';
			model.title = 'お申込みありがとうございます！';
			model.massage1 = 'コースの変更申込みを受け付けました。<br/>内容を確認させていただき、担当部門よりご連絡申し上げます。';
			model.bikou1 = '※ご連絡は「お客さま情報・変更」に登録されている<br/>連絡先メールアドレス/電話番号にいたします。';
			model.massage2 = '連絡先メールアドレスに確認メールを送信しました。';
		}
		else if (type === 'add_nhk_complete') {
			model.title = 'お申込みありがとうございます！';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = 'NHK団体一括支払いをお申込みいただき、誠にありがとうございます。<br/>内容確認させていただき、専用申込書をお送りいたします。<br/>ご案内事項がある場合、弊社担当より数日中にご連絡致します。';
			model.bikou1 = '※ご連絡は「お客さま情報・変更」に登録されている<br/>連絡先メールアドレス/電話番号にいたします。';
		}
		else if (type === 'edit_nhk_complete') {
			model.title = 'NHK団体一括支払い 支払い期間変更申込み完了';
			model.massage1 = 'NHK団体一括支払い 支払い期間変更申込みが完了しました。';
			model.bikou1 = '※連絡先メールアドレスに確認メールを送信しました。';
		}
		else if (type === 'mod_nhk_complete') {
			model.title = 'NHK団体一括支払い解約完了';
			model.massage1 = 'ＮＨＫ団体一括支払い解約申込みが完了しました。';
			model.bikou1 = '※連絡先メールアドレスに確認メールを送信しました。';
		}	
		else if (type === 'add_phone_complete') {
			model.title = 'お申込みありがとうございます！';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = 'iTSCOM PHONEをお申込みいただき、誠にありがとうございます。<br/>弊社担当より数日中にご連絡いたします。';
			model.bikou1 = '※ご連絡はお客さま情報の確認・変更に登録されている<br/>ご案内事項がある場合、弊社担当より数日中にご連絡いたします。';	
		}	
		else if (type === 'add_tv_complete') {
			model.title = 'お申込みありがとうございます！';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = 'iTSCOM TVをお申込みいただき、誠にありがとうございます。<br/>コースの追加申込みを受け付けました。<br/>弊社担当より数日中にご連絡いたします。';
			model.bikou1 = '※ご連絡はお客さま情報の確認・変更に登録されている<br/>連絡先メールアドレス/電話番号にいたします。';	
		}	
		else if (type === 'mod_tv_complete') {
			const modInfo = this.m_MypageWizardMngr.getCache().m_TvBaseModel;
			model.title = 'お申込みありがとうございます！';
			model.user = this.m_CustomerName + 'さん';
			model.bikou1 = '※ご連絡はお客さま情報の確認・変更に登録されている<br/>連絡先メールアドレス/電話番号にいたします。<br/>※利用料は、コース変更が完了した翌月から変更となります。';	
			if (modInfo.directResult === '直接申込') {
				model.massage1 = 'iTSCOM TVをお申込みいただき、誠にありがとうございます。<br/>コースの変更申込みを受け付けました。<br/>' + modInfo.startDate + 'より視聴いただけます。';
			}
			else {
				model.massage1 = 'iTSCOM TVをお申込みいただき、誠にありがとうございます。<br/>コースの変更申込みを受け付けました。<br/>ご案内事項がある場合、弊社担当より数日中にご連絡いたします。';
			}
		}	
		else if (type === 'mod_tvequip_complete') {
			model.title = 'お申込みありがとうございます！';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = 'iTSCOM TVをお申込みいただき、誠にありがとうございます。<br/>機器の変更申込みを受け付けました。<br/>担当者より数日中にご連絡させていただきます。';
			model.bikou1 = '※連絡先メールアドレスに確認メールを送信しました。';	
		}
		else if (type === 'add_net_complete') {
			const addInfo = this.m_MypageWizardMngr.getCache().m_NetInfo;
			model.title = 'お申込みありがとうございます！';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = 'iTSCOM NETをお申込みいただき、誠にありがとうございます。<br/>コースの追加申込みを受け付けました。';
			if (!addInfo.directflg) {
				model.massage3 = '弊社担当より数日中にご連絡いたします。';
				model.bikou1 = '※ご連絡はお客さま情報の確認・変更に登録されている<br/>連絡先メールアドレス/電話番号にいたします。';
			}
			model.massage2 = '連絡先メールアドレスに確認メールを送信しました。';
		}
		else if (type === 'mod_net_complete') {
			const modInfo = this.m_MypageWizardMngr.getCache().m_NetInfo;
			model.title = 'お申込みありがとうございます！';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = 'iTSCOM NETをお申込みいただき、誠にありがとうございます。<br/>コースの変更申込みを受け付けました。';
			if (!modInfo.directflg || modInfo.directResult === '仮申込み') {
				model.massage3 = 'ご案内事項がある場合、弊社担当より数日中にご連絡いたします。'
				model.bikou1 = '※ご連絡はお客さま情報の確認・変更に登録されている<br/>連絡先メールアドレス/電話番号にいたします。<br/>※利用料は、コース変更が完了した翌月から変更となります。';
			}
			model.massage2 = '連絡先メールアドレスに確認メールを送信しました。';
		}
		else if (type === 'add_meshWifi_complete') {
			model.title = 'お申込みありがとうございます！';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = 'メッシュWi-Fiをお申込みいただき、誠にありがとうございます。<br/>ご案内事項がある場合、弊社担当より数日中にご連絡いたします。';
			model.bikou1 = '※ご連絡はお客様情報の確認・変更に登録されている<br/>連絡先メールアドレス/電話番号にいたします。';	
		}
		// else if (type === 'magazine1') {
		// 	model.user = this.m_CustomerName;
		// 	model.title = 'お申込みありがとうございます！';
		// 	model.massage1 = 'iTSCOM TVをお申込みいただき、誠にありがとうございます。<br/>コースの追加申込みを受け付けました。<br/>弊社担当より数日中にご連絡致します。';
		// 	model.bikou1 = '※ご連絡はお客さま情報の確認・変更に登録されている<br/>連絡先メールアドレス/電話番号にいたします。';
		// }
		// else if (type === 'magazine2') {
		// 	model.user = this.m_CustomerName;
		// 	model.title = 'iTSCOM magazineの解約を受け付けました。';
		// 	model.massage1 = '以下の内容で変更が完了しました。';
		// 	model.massage2 = '連絡先メールアドレスに確認メールを送信しました。';
		// }
		else if (type === 'tokoton_support_complete') {
			model.title = 'お申込みありがとうございます！';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = 'とことんサポートをお申込みいただき、誠にありがとうございます。<br/>ご案内事項がある場合、弊社担当より数日中にご連絡いたします。';
			model.bikou1 = '※ご連絡は「お客さま情報・変更」に登録されている<br/>連絡先メールアドレス/電話番号にいたします。';
		}
		else if (type === 'relocate_complete') {
			model.title = 'お申込みありがとうございます！';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = '機器移動のお申込みいただき、誠にありがとうございます。<br/>ご案内事項がある場合、弊社担当より数日中にご連絡いたします。';
			model.bikou1 = '※ご連絡はお客さま情報の確認・変更に登録されている<br/>連絡先メールアドレス/電話番号にいたします。';
		}
		else if (type === 'constr_complete') {
			this.setViewMode('遷移先', 'トップ');
			model.title = 'お申込みありがとうございます！';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = '工事日程に関するお手続きを受付いたしました。<br/>ご案内事項がある場合、弊社担当より数日中にご連絡いたします。';
			model.bikou1 = '※ご連絡はお客さま情報の確認・変更に登録されている<br/>連絡先メールアドレス/電話番号にいたします。';
			// const detail: ConstrBaseModel = this.m_MypageWizardMngr.getCache().m_ConstrBaseModel;
			// const date = detail.workPossibleList.workPossibleList.possible_date.substring(0, 4) + '/' + detail.workPossibleList.workPossibleList.possible_date.substring(4, 6) + '/' + detail.workPossibleList.workPossibleList.possible_date.substring(6, 8);
			// model.massage2 = '連絡先メールアドレスに受付致しました予定日記載のメールを送信しました。';
			// model.massage1 = '工事予定日　'+ date + '（' + detail.workPossibleList.dayOfWeek + '）　' + detail.workPossibleList.preferredTime + 'の間の到着';
			// model.bikou1 = '※ご連絡はお客さま情報の確認・変更に登録されている<br/>連絡先メールアドレス/電話番号にいたします。';
			// model.massage2 = '連絡先メールアドレスに受付致しました予定日記載のメールを送信しました。';
		}
		else if (type === 'constr_change_complete') {
			this.setViewMode('遷移先', 'トップ');
			model.title = 'お申込みありがとうございます！';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = '工事日程に関するお手続きを受付いたしました。<br/>ご案内事項がある場合、弊社担当より数日中にご連絡いたします。';
			model.bikou1 = '※ご連絡はお客さま情報の確認・変更に登録されている<br/>連絡先メールアドレス/電話番号にいたします。';
		}
		else if (type === 'constr_cancel_complete') {
			this.setViewMode('遷移先', 'トップ');
			model.title = '予定キャンセルを受付いたしました。';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = 'ご案内事項がある場合、弊社担当より数日中にご連絡いたします。';
			model.bikou1 = '※ご連絡はお客さま情報の確認・変更に登録されている<br/>連絡先メールアドレス/電話番号にいたします。';
		}
		else if (type === 'call_forwarding_complete') {
			model.title = 'お申込みありがとうございます！';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = 'ケーブルプラス電話オプションをお申込みいただき、誠にありがとうございます。<br/>着信転送を受け付けました。<br/>ご案内事項がある場合、弊社担当より数日中にご連絡いたします。';
			model.bikou1 = '※ご連絡はお客さま情報の確認・変更に登録されている<br/>連絡先メールアドレス/電話番号にいたします。';
		}
		else if (type === 'add_kattobi_complete') {
			model.title = 'お申込みありがとうございます！';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = 'かっとび光をお申込みいただき、誠にありがとうございます！<br/>弊社担当より数日中にご連絡いたします。';
			model.bikou1 = '※ご連絡はお客さま情報の確認・変更に登録されている<br/>連絡先メールアドレス/電話番号にいたします。';
			model.massage2 = '連絡先メールアドレスに確認メールを送信しました。';
		}
		else if (type === 'mod_kattobi_complete') {
			model.title = 'お申込みありがとうございます！';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = 'かっとび光をお申込みいただき、誠にありがとうございます！<br/>弊社担当より数日中にご連絡いたします。';
			model.bikou1 = '※ご連絡はお客さま情報の確認・変更に登録されている<br/>連絡先メールアドレス/電話番号にいたします。';
			model.massage2 = '連絡先メールアドレスに確認メールを送信しました。';
		}
		else if (type === 'terminal_regist_complete') {
			this.setViewMode('遷移先', 'トップ');
			model.title = 'お申込みありがとうございます！';
			model.user = this.m_CustomerName + 'さん';
			model.massage1 = '機器登録いただき、誠にありがとうございます。<br/>本画面表示後、3分ほどお待ちいただき<br/>モデムのONLINEランプが点灯しているかのご確認をお願いします。';
			model.bikou1 = '※インターネット接続に関する詳細はモデムに同封されている<br/>ご案内をご確認ください。';
		}
		this.m_CompleteModel = model;
		
	}
	// --------------------------------------------------------------------------
}
