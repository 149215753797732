import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../wpr-framework/view/component/WprBaseViewComponent';
import { UsageFeeTabView } from './UsageFeeTabView';

import styles from './UsageFeeTab.scss';
import { TableCell, Typography, Paper, AccordionSummary, Accordion, TableContainer, TableBody, Table, TableRow, AccordionDetails, TableHead, Grid } from '@material-ui/core';
import { MatTypography } from '../../../../wpr-framework/component/controls/material/MatTypography';
import { MatAccordion } from '../../../../wpr-framework/component/controls/material/MatAccordion';
import { ExpandMore, GTranslate } from '@material-ui/icons';
import { WprRowInfo } from '../../../../wpr-framework/component/props/WprRowInfo';
import { MatButton } from '../../../../wpr-framework/component/controls/material/MatButton';
import { UsagePDFDownloadDialog } from '../dialog/usage_p_d_f_download_dialog/UsagePDFDownloadDialog';
import { UsageCplusGraphDialog } from '../dialog/usage_cplus_graph_dialog/UsageCplusGraphDialog';
import { UsageToukyuGraphDialog } from '../dialog/usage_toukyu_graph_dialog/UsageToukyuGraphDialog';
import { UsageServiceInfo } from '../../../models/UsageServiceInfo';
import { UsageCourseInfo } from '../../../service/models/usagedetails/UsageCourseInfo';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import LaunchIcon from '@mui/icons-material/Launch';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { TokyuItemInfo } from '../../../models/TokyuItemInfo';
import { TokyuServiceInfo } from '../../../models/TokyuServiceInfo';

import pdfDownload from '/shared/image/usagedetails/pdfDownload.png';
import { WprAnchor } from '../../../../wpr-framework/component/controls/WprAnchor';
import { WprContainer } from '../../../../wpr-framework/component/controls/WprContainer';

/**
 * 利用明細：利用料金タブ用コンポーネント
 */
export class UsageFeeTab extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new UsageFeeTabView(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 利用明細：利用料金タブビュー情報 */
	public get viewInfo(): UsageFeeTabView { return this.view as UsageFeeTabView; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * スタイルオブジェクト取得
	*/
	public getStylesObject(): any { return styles; }

	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('UsageServiceInfoList', null, this.onUsageServiceInfoListRender);
		this.addListRender('TokyuServiceInfoList', null, this.onTokyuServiceInfoListRender);
		this.addListRender('InvoiceStatementList', null, this.onInvoiceStatementListRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
				<div className='tabArea'>
					<Grid container className='usageTop'>
						<Grid item md={6} xs={12} className=''>
							<div className='link_blue'>
								<WprAnchor name='usageInfoLink' view={this.view}>利用明細・請求に関するご案内はこちら＞</WprAnchor>
							</div>
							{/* <MatButton name='usageInfoLink' variant='text' view={this.view}>利用明細・請求に関するご案内はこちら
								<NavigateNextIcon/>
							</MatButton> */}
						</Grid>
						<Grid item md={6} xs={12} className='usageTopTitle'>
							<div className='disp-flex'>
								<MatTypography name='month' view={this.view} />
								<Typography>ご請求明細</Typography>
							</div>
						</Grid>
					</Grid>
					{/* 明細不足項目の追加 */}
					<Grid container className='addressArea'>
						<Grid item xs={12} md={6} className='contractInfo'>
							<Typography className='title'>ご契約情報</Typography>
							<MatTypography name='contractName' className='detail' view={this.view} />
							<MatTypography name='contractAddress' className='detail' view={this.view} />
						</Grid>
						<Grid item xs={12} md={6} className='billimgInfo'>
							<Typography className='title'>ご請求情報</Typography>
							<MatTypography name='billingName' className='detail' view={this.view} />
							<MatTypography name='billingAddress' className='detail' view={this.view} />
						</Grid>
					</Grid>
					<div className='usageFeeTotal'>
						<Typography style={{ width: '100%' }} align='center'>合計ご請求金額</Typography>
						<div>
							<div>
								<MatTypography name='total' view={this.view} />
								<Typography className='usageFeeUnit' style={{ fontSize: '1rem' }} component='span'> 円</Typography>
							</div>
						</div>
					</div>
					<WprContainer mode={'合算.有'} view={this.view} >
						<Typography className='gassanText'>
							お支払い方法の設定(口座設定)完了までに４ヶ月以上経過しているお客さまは、
						</Typography>	
						<Typography className='gassanText'>
							該当期間のケーブルプラス電話・東急でんき&ガスの明細が正しく表示されない場合がございます。
						</Typography>	
					</WprContainer>
					<Typography className='gassanText'>
						毎月の請求金額の確定日は11日頃になります。
					</Typography>
					{this.onStatementRender()}
					<WprContainer mode={'インボイス.無'} view={this.view}>
						<div className='invoiceDetailAcc feeHeader'>
							<div className='invoiceDetailTitle'>
								<Typography className='feeHeaderPoint'>●</Typography>
								<Typography style={{ width: '100%' }}>インボイス明細</Typography>
							</div>
						</div>
						<Typography  className='noStatementMsg'>
							インボイス制度開始前のご利用明細です
						</Typography>
					</WprContainer>
					<WprContainer mode={'インボイス.有'} view={this.view}>
						<div className='feeDetailsAcc invoiceDetailAcc feeHeader'>
							<MatAccordion mode={"mode"} name={"name"} view={this.view}>
								<AccordionSummary expandIcon={<ExpandMore />}>
									<div className='invoiceDetailTitle'>
										<Typography className='feeHeaderPoint'>●</Typography>
										<Typography style={{ width: '100%' }}>インボイス明細</Typography>
									</div>
								</AccordionSummary>
								<AccordionDetails className='itemDetails'>
									{this.onInvoiceStatementRender()}
								</AccordionDetails>
							</MatAccordion>
						</div>
					</WprContainer>
					<div className='usageFeeAnnotation'>
						※明細の閲覧期限は24ヶ月です。<br/>
						25ヶ月目以降にあたる過去明細書は、WEB上からはご確認いただけなくなりますので、<br/>
						必要に応じて印刷などのご対応をお願いいたします。<br/>
						※でんき・ガス、ケーブルプラス電話等の各社より当社が譲り受けたサービスの債権の一覧/<br/>
						NHK団体一括支払のインボイス対応に関しましては、当社ホームページに記載しておりますので、ご確認ください。<br/>
						<WprAnchor className='link_blue' name='invoiceFaqLink' view={this.view}>https://faq.itscom.net/faq/show/37725</WprAnchor><br/>
						※「インボイス明細」欄ではユニバーサルサービス料、オプションサービス料等を「ケーブルプラス電話　その他」に包含して記載しております。<br/>
						「ご利用料金明細欄」と比較して「ケーブルプラス電話　その他」の項目に金額の相違がございますが、合計金額に差異はございません。<br/>
						※繰越金が発生している等、ご入金状況によりご利用料金明細とインボイス明細の合計金額が相違する場合がございます。<br/>
						ご了承ください。
						<br/>
						※明細に★が表示されているサービスはまいにち充実プラン・お得パックの構成サービスとなっております。
						<br/>
						※月額ご利用料金に料金調整が発生した場合、明細部分の金額に一部ずれが生じる場合がございます。
					</div>
				</div>
				<UsagePDFDownloadDialog name='PDFダウンロード' className='pdf-dlg' view={this.view} subtitle={'ご希望の期間と表示方法を指定し、ダウンロードをクリックしてください。'} />
				<UsageCplusGraphDialog name='グラフ' view={this.view} className='glaph-dlg' subtitle={this.viewInfo.m_CablePlusGraphPeriod} />
				<UsageToukyuGraphDialog name='東急グラフ' view={this.view} className='tokyu-glaph-dlg' />
				<div className='pdfDownloadBtnFooter'>
					<div className='submitBtnOrange pdfDownloadBtn'>
						<MatButton name='pdfDownload' color='primary' view={this.view}>
							ご請求明細をPDFでダウンロード
							<div>
								<img src={pdfDownload} />
							</div>
						</MatButton>
					</div>
				</div>
			</>
		);
	}
	// --------------------------------------------------------------------------
	/**
	 * 請求明細の表示
	 */
	public onStatementRender(): any {
		const statementList = this.state.data.usageServiceInfoList;
		const tokyuStatementList = this.state.data.TokyuServiceInfoList;
		if (ObjectUtil.isNotNullOrEmptyArray(statementList)) {
			return (
				<>
					{this.renderList('UsageServiceInfoList')}
				</>
			)
		} else if (ObjectUtil.isNotNullOrEmptyArray(tokyuStatementList)) {
			return (
				<>
					{this.tokyuDetailsRender(true)}
				</>
			)
		} else {
			return (
				<>
				</>
			)
		}
	}
	// --------------------------------------------------------------------------
	/**
	 * 請求明細の表示
	 */
	public onInvoiceStatementRender(): any {
		const statementList = this.state.data.InvoiceStatementList;
		if (ObjectUtil.isNotNullOrEmptyArray(statementList)) {
			return (
				<>
					{this.renderList('InvoiceStatementList')}
				</>
			)
		} else {
			return (
				<>
				</>
			)
		}
	}
	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=UsageServiceInfoList
	 */
	public onUsageServiceInfoListRender(rowInfo: WprRowInfo): any {
		const serviceData: UsageServiceInfo = rowInfo.rowData as UsageServiceInfo;

		/** 
		 * 東急でんき＆ガス表示条件
		 * ①iTSCOM HOME以降の明細が無い場合→末尾に表示
		 * ②イッツコムモバイル 以降。iTSCOM HOMEより前
		 * ※「5」：東急明細の表示順インデクス
		 */
		let nextInfo: UsageServiceInfo = this.state.data.usageServiceInfoList[rowInfo.index + 1];
		let tokyuShowFlg: boolean;
		if (ObjectUtil.isNotNullOrEmptyArray(this.state.data.TokyuServiceInfoList)) {
			if (ObjectUtil.isNullOrUndefined(nextInfo) && serviceData.dispSort < 5) {
				tokyuShowFlg = true;	// ①
			} else if (serviceData.dispSort < 5 && 5 < nextInfo.dispSort) {
				tokyuShowFlg = true;	// ②
			}
		}

		const serviceCategory: string = serviceData.serviceCategory;
		const courseList: UsageCourseInfo[] = serviceData.courseInfoList as UsageCourseInfo[];
		return (
			<>
				<div className='whitespace' />
				<Grid container className='feeHeader'>
					<Grid item md={6} xs={12}>
						<div className='disp-flex feeTitle'>
							<Typography className='feeHeaderPoint'>●</Typography>
							<MatTypography name='serviceCategory' view={this.view} row={rowInfo} />
						</div>
					</Grid>
					<Grid item md={6} xs={12}>
						<div className='feeTotal graphBtn'>
							{this.showGraphButton(serviceCategory)}
							<div>
								<Typography component='span' align='right'>合計</Typography>
								<MatTypography name='taxIn' view={this.view} row={rowInfo} />
								<Typography component='span' style={{ paddingLeft: '0.2rem' }}> 円</Typography>
							</div>
						</div>
					</Grid>
				</Grid>
				{this.onCourseListRender(courseList, serviceCategory)}
				{this.itsMobileLinkRender(serviceCategory)}
				<div className='whitespace' />
				{this.tokyuDetailsRender(tokyuShowFlg)}
			</>
		);
	}

	/**
	 * 東急でんき＆ガス：明細の表示
	 * @param showFlg
	 * @returns 
	 */
	public tokyuDetailsRender(showFlg: boolean): any {
		if (showFlg) {
			return (
				<>
					<Grid container className='feeHeader'>
						<Grid item md={6} xs={12}>
							<div className='disp-flex feeTitle'>
								<Typography className='feeHeaderPoint'>●</Typography>
								<Typography>東急でんき＆ガスご利用料金</Typography>
							</div>
						</Grid>
						<Grid item md={6} xs={12}>
							<div className='feeTotal graphBtn'>
								{this.showGraphButton('東急でんき＆ガス')}
								<div>
									<Typography component='span' align='right'>合計</Typography>
									<MatTypography name='tokyuTotalTaxIn' view={this.view} />
									<Typography component='span' style={{ paddingLeft: '0.2rem' }}> 円</Typography>
								</div>
							</div>
						</Grid>
					</Grid>
					{this.renderList('TokyuServiceInfoList')}
					{this.tokyuLinkRender()}
					<div className='whitespace' />
				</>
			);
		}
		return (
			<></>
		);
	}

	/**
	 * リスト名=TokyuServiceInfoListRender
	 */
	public onTokyuServiceInfoListRender(rowInfo: WprRowInfo): any {
		const toukyuData: TokyuServiceInfo = rowInfo.rowData as TokyuServiceInfo;
		// 合算対応 - アコーディオンが複数同時に問題改修
		const accMode = rowInfo.index.toString();
		const accName = (rowInfo.index).toString() + '_name';

		return (
			<>
				<div className='feeDetailsAcc'>
					<MatAccordion mode={accMode} name={accName} view={this.view}>
						<AccordionSummary expandIcon={<ExpandMore />}>
							<Grid container>
								<Grid item md={9} xs={12}>
									<div className='tokyuNameHeader'>
										<MatTypography name='tokyuServiceCategory' view={this.view} row={rowInfo} />
									</div>
									{this.showTokyuContInfo(toukyuData)}
									{this.showUsagePeriod(toukyuData.usageMonth, toukyuData.fromMonth, toukyuData.toMonth)}
								</Grid>
								<Grid item md={3} xs={12}>
									<div className='tokyuTotal'>
										<span><MatTypography name='tokyuTaxIn' view={this.view} row={rowInfo} /></span>
										<Typography>円</Typography>
									</div>
									{this.showTokyuConsumption(toukyuData)}
								</Grid>
							</Grid>
						</AccordionSummary>
						<AccordionDetails className='itemDetails'>
							<TableContainer>
								<Table className='feeDetailsTable'>
									<colgroup>
										<col style={{ width: '90%' }} />
										<col style={{ width: '10%' }} />
									</colgroup>
									<TableHead>
										{
											toukyuData.tokyuItemInfoList.map((toukyuItem) => {
												return (
													<>
														<TableRow className='courseItemDetailsTable'>
															<TableCell className='tokyuSubItem courseItemName'>
																<Typography component='span' style={{ fontWeight: '700' }}>{toukyuItem.item}</Typography>
																{this.tokyuSubItemNameRender(toukyuItem.tokyuItemSubList)}
															</TableCell>
															<TableCell className='courseAmount'>
																{this.setTokyuAmount(toukyuItem, '')}
																{this.tokyuSubItemAmountRender(toukyuItem.tokyuItemSubList)}
															</TableCell>
														</TableRow>
													</>
												);
											})
										}
									</TableHead>
								</Table>
							</TableContainer>
						</AccordionDetails>
					</MatAccordion>
				</div>
				<div className='whitespace' />
			</>
		);
	}

	/**
	 * リスト名=TokyuServiceInfoList
	 * @param showFlg
	 * @returns 
	 */
	public onInvoiceStatementListRender(rowInfo: WprRowInfo): any {
		const serviceData: UsageServiceInfo = rowInfo.rowData as UsageServiceInfo;

		const serviceCategory: string = serviceData.serviceCategory;
		const courseList: UsageCourseInfo[] = serviceData.courseInfoList as UsageCourseInfo[];
		return (
			<>
				<div className='whitespace' />
				<Grid container className='feeHeader'>
					<Grid item md={6} xs={12}>
						<div className='disp-flex feeTitle'>
							<Typography className='feeHeaderPoint'>●</Typography>
							<MatTypography name='invoiceServiceCategory' view={this.view} row={rowInfo} />
						</div>
					</Grid>
					<Grid item md={6} xs={12}>
						<div className='feeTotal graphBtn'>
							{this.showGraphButton(serviceCategory)}
							<div>
								<Typography component='span' align='right'>合計</Typography>
								<MatTypography name='invoiceTaxIn' view={this.view} row={rowInfo} />
								<Typography component='span' style={{ paddingLeft: '0.2rem' }}> 円</Typography>
							</div>
						</div>
					</Grid>
				</Grid>
				{this.onInvoiceCourseListRender(courseList, serviceCategory)}
				<div className='whitespace' />
			</>
		);
	}


	// privateメソッド --------------------------------------------------------------------------------
	/**
	 * コース情報リストの描画
	 * @param courseList 
	 * @param serviceCategory 	サービスカテゴリ（アコーディオンのモード名に使用）
	 */
	private onCourseListRender(courseList: UsageCourseInfo[], serviceCategory: string): any {
		let courseCnt: number = 0;
		return (
			<>
				{
					courseList.map((item) => {
						courseCnt++;
						let accMode = serviceCategory + String(courseCnt);
						let accName = serviceCategory + '0' + String(courseCnt);
						return (
							<>
								<div className='feeDetailsAcc'>
									<MatAccordion mode={accMode} name={accName} view={this.view}>
										<AccordionSummary expandIcon={<ExpandMore />}>
											<TableContainer>
												<Table className='serviceInfoTable'>
													<TableRow>
														<TableCell className='serviceName' >
															<div className='courseNameHeader'>
																<Typography component='span'>{item.courseName}
																
																{this.showPhoneNumberArea(item.phoneNumber)}
																</Typography>
															</div>
															{this.showUsagePeriod(null, item.fromDate, item.toDate)}
														</TableCell>
														<TableCell className='serviceTotal'>
															<div>
																<Typography component='span'>{item.taxIn.toLocaleString()}</Typography>
																<Typography>円</Typography>
															</div>
														</TableCell>
													</TableRow>
												</Table>
											</TableContainer>
										</AccordionSummary>
										<AccordionDetails className='itemDetails'>
											<TableContainer>
												<Table className='feeDetailsTable'>
													<colgroup>
														<col style={{ width: '90%' }} />
														<col style={{ width: '10%' }} />
													</colgroup>
													<TableHead>
														{item.itemList.map((feeItem) => {
															return (
																<>
																	<TableRow className='courseItemDetailsTable'>
																		<TableCell className='courseItemName'>
																			<Typography component='span' style={{ fontWeight: '700' }}>{feeItem.name}</Typography>
																			{this.showPaymentMethod(feeItem.nhkPaymentType)}
																		</TableCell>
																		<TableCell className='courseAmount'>
																			<span className='feeDetails'>
																				<div>
																					<Typography component='span'>{feeItem.taxIn.toLocaleString()}</Typography>
																					<Typography>円</Typography>
																				</div>
																				<div className='taxType'>
																					<Typography>{this.convertTaxType(feeItem.taxTypeCd)}</Typography>
																				</div>
																			</span>
																		</TableCell>
																	</TableRow>
																</>
															);
														})}
													</TableHead>
												</Table>
											</TableContainer>
										</AccordionDetails>
									</MatAccordion>
								</div>
								<div className='whitespace' />
							</>
						);
					})
				}
			</>
		);
	}

	/**
	 *インボイス コース情報リストの描画
	 * @param courseList 
	 * @param serviceCategory 	サービスカテゴリ（アコーディオンのモード名に使用）
	 */
	private onInvoiceCourseListRender(courseList: UsageCourseInfo[], serviceCategory: string): any {
		return (
			<>
				{
					courseList.map((item) => {
						return (
							<>
								<div className='invoiceFeeDetails'>
									<TableContainer>
										<Table className='serviceInfoTable'>
											<TableRow>
												<TableCell className='serviceName' >
													<div className='courseNameHeader'>
														<Typography component='span'>{item.courseName}</Typography>
													</div>
												</TableCell>
												<TableCell className='serviceTotal'>
													<div>
														<Typography component='span'>{item.taxIn.toLocaleString()}</Typography>
														<Typography>円</Typography>
													</div>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell className='serviceName' >
													<div>
														<Typography component='span'>{item.registrationNumber}</Typography>
													</div>
												</TableCell>
											</TableRow>
										</Table>
									</TableContainer>
									<TableContainer>
										<Table className='feeDetailsTable'>
											<colgroup>
												<col style={{ width: '90%' }} />
												<col style={{ width: '10%' }} />
											</colgroup>
											<TableHead>
												{item.itemList.map((feeItem) => {
													return (
														<>
															<TableRow className='courseItemDetailsTable'>
																<TableCell className='courseItemName'>
																	<Typography component='span' style={{ fontWeight: '700' }}>{feeItem.name}</Typography>
																</TableCell>
																<TableCell className='courseAmount'>
																	<span className='feeDetails'>
																		<div>
																			<Typography component='span'>{this.dispAmount(feeItem.noAmountFlg, feeItem.taxIn)}</Typography>
																			<Typography>{this.dispYen(feeItem.noAmountFlg)}</Typography>
																		</div>
																		<div className='taxType'>
																			<Typography>{this.convertTaxType(feeItem.taxTypeCd)}</Typography>
																		</div>
																	</span>
																</TableCell>
															</TableRow>
														</>
													);
												})}
											</TableHead>
										</Table>
									</TableContainer>
								</div>
								<div className='whitespace' />
							</>
						);
					})
				}
			</>
		);
	}

	/**
	 * 東急でんき＆ガス：サブ項目名称
	 * @param subList 
	 * @returns 
	 */
	private tokyuSubItemNameRender(subList: TokyuItemInfo[]): any {
		if (ObjectUtil.isNotNullOrEmptyArray(subList)) {
			return (
				<>
					{
						subList.map((subItem) => {
							return (
								<>
									<Typography>{subItem.item}</Typography>
								</>
							);
						})
					}
				</>
			);
		}
		return (
			<>
			</>
		);
	}

	/**
	 * 東急でんき＆ガス：サブ項目金額
	 * @param subList 
	 * @returns 
	 */
	private tokyuSubItemAmountRender(subList: TokyuItemInfo[]): any {
		if (ObjectUtil.isNotNullOrEmptyArray(subList)) {
			return (
				<>
					{
						subList.map((subItem) => {
							return (
								<>
									{this.setTokyuAmount(subItem, 'tokyuSubItemAmount')}
								</>
							);
						})
					}
				</>
			);
		}
		return (
			<>
			</>
		);
	}


	/**
	 * 電話番号の表示
	 * @param phoneNumber 
	 * @returns 
	 */
	private showPhoneNumberArea(phoneNumber: string): any {
		if (phoneNumber != null) {
			return (
				<>
					<Typography component='span' className='phoneNumber'>（電話番号：{phoneNumber}）</Typography>
				</>
			);
		}
		return (
			<>
			</>
		);
	}

	/**
	 * グラフボタンの表示
	 * @param serviceCategory 
	 */
	private showGraphButton(serviceCategory: string): any {
		if (serviceCategory == 'ケーブルプラス電話') {
			return (
				<>
					<MatButton name='graph' color='primary' view={this.view} className='usageButton' >グラフ表示</MatButton>
				</>
			);
		} else if (serviceCategory == '東急でんき＆ガス') {
			return (
				<>
					<MatButton name='toukyuGraph' color='primary' view={this.view} className='usageButton'>一覧表示</MatButton>
				</>
			);
		}
		return (
			<>
			</>
		);
	}

	/**
	 * NHKの支払いタイプを表示する
	 * @param nhkPaymentType 	「〇ヵ月前払い」
	 */
	private showPaymentMethod(nhkPaymentType: string): any {
		if (nhkPaymentType != null) {
			return (
				<>
					<Typography component='span' className='lumpSumPayment' style={{ fontWeight: 'normal' }}>{nhkPaymentType}</Typography>
				</>
			);
		}
		return (
			<>
			</>
		);
	}

	/**
	 * 東急でんき＆ガス：金額を表示
	 * @param item 
	 * @returns 
	 */
	private setTokyuAmount(item: TokyuItemInfo, className: string): any {
		if (item.senFlg) {	// "銭"を表示
			let amount: string;
			if (item.minusZeroFlg) // -0の場合
				amount = '-' + String(item.yen);
			else
				amount = String(item.yen)
			return (
				<>
					<span className='feeDetails'>
						<div className={className}>
							<Typography component='span'>{Number(amount).toLocaleString()}</Typography>
							<Typography>円</Typography>
							<Typography component='span' style={{ paddingRight: '' }}>{item.sen.toLocaleString()}</Typography>
							<Typography>銭</Typography>
						</div>
					</span>
				</>
			);
		}
		// "円"のみ表示
		return (
			<>
				<span className='feeDetails'>
					<div className={className}>
						<Typography component='span'>{item.yen.toLocaleString()}</Typography>
						<Typography>円</Typography>
					</div>
				</span>
			</>
		);
	}

	/**
	 * 契約情報の表示
	 * ※その他の場合は表示しない
	 * @param selectedData 
	 */
	private showTokyuContInfo(selectedData: TokyuServiceInfo): any {
		if (selectedData.serviceCategory === 'その他') {
			return (
				<>
				</>
			)
		} else {
			return (
				<>
					<div className='tokyuContDetails'>
						<span>
							<Typography>供給地点特定番号：{selectedData.supplyPointNum}</Typography>
						</span>
						<span>
							<Typography>契約プラン：{selectedData.contractPlan}</Typography>
						</span>
					</div>
				</>
			)
		}
	}

	/**
	 * 契約情報の表示
	 * ※その他の場合は表示しない
	 * @param selectedData 
	 */
	private showTokyuConsumption(selectedData: TokyuServiceInfo): any {
		if (selectedData.serviceCategory === 'その他') {
			return (
				<>
				</>
			)
		} else {
			return (
				<>
					<div className='toukyuConsumption'>
						<Typography component='span'>ご使用量：</Typography>
						<div>
							<Typography>{selectedData.consumption}</Typography>
						</div>
						<Typography component='span' style={{ marginLeft: '0.2rem' }}>{selectedData.consumptionUnit}</Typography>
					</div>
				</>
			)
		}
	}

	/**
	 * 東急でんき＆ガス：請求詳細リンクの表示
	 * @returns 
	 */
	private tokyuLinkRender(): any {
		return (
			<>
				<Grid container>
					<Grid item xs={12} className=''>
						<div className='link_blue'>
							<WprAnchor name='tokyuLink' view={this.view}>東急でんき＆ガスの請求について詳しく見る＞</WprAnchor>
						</div>
					</Grid>
				</Grid>
			</>
		);
	}

	/**
	 * イッツコム モバイル：請求詳細リンクの表示
	 * @param serviceCategory 
	 * @returns 
	 */
	private itsMobileLinkRender(serviceCategory: string): any {
		if (serviceCategory == 'イッツコム モバイル') {
			return (
				<>
					<Grid container spacing={3}>
						<Grid item xs={12} className='sumahoMyPageLink'>
							<div>
								※データ残量・通話履歴は
								<WprAnchor name='mypageSmahoLink' className='link_underline' view={this.view}>
									イッツコムスマホ　マイページ
									<LaunchIcon style={{height: '10px', width: '10px'}} />
								</WprAnchor>	
								からご確認いただけます。
							</div>
						</Grid>
						<Grid item xs={12} className=''>
							<div className='link_blue'>
								<WprAnchor name='mobileDataLink' view={this.view}>データ通信量の確認方法について詳しく見る＞</WprAnchor>
							</div>
							{/* <MatButton name='mobileDataLink' variant='text' color='primary' view={this.view}>
								データ通信量の確認方法について詳しく見る
								<NavigateNextIcon />
							</MatButton> */}
						</Grid>
					</Grid>
				</>
			);
		}
		return (
			<>
			</>
		);
	}

	/**
	 * 利用期間の表示
	 * @param fromDate 
	 * @param toDate 
	 */
	private showUsagePeriod(usageMonth: string, fromDate: string, toDate: string): any {
		let period: string = '';
		if (ObjectUtil.isNotNullOrUndefined(fromDate) && fromDate.length === 8) {
			const year: string = fromDate.substring(0, 4);
			const month: string = this.convertZeroOmit(fromDate.substring(4, 6));
			if (usageMonth != null) {
				const usageyear: string = usageMonth.substring(0, 4);
				const usagemonth: string = this.convertZeroOmit(usageMonth.substring(4, 6));
				period += usageyear + '年' + usagemonth + '月ご利用分';
			}
			else {
				period += year + '年' + month + '月ご利用分';
			}
			const day: string = this.convertZeroOmit(fromDate.substring(6, 8));
			if (ObjectUtil.isNotNullOrUndefined(toDate) && toDate.length === 8) {
				const toYear: string = toDate.substring(0, 4);
				const toMonth: string = this.convertZeroOmit(toDate.substring(4, 6));
				const toDay: string = this.convertZeroOmit(toDate.substring(6, 8));
				period += '(' + year + '/' + month + '/' + day + '~'
					+ toYear + '/' + toMonth + '/' + toDay + ')';
			}
			return (
				<>
					<Typography className='period'>{period}</Typography>
				</>
			);
		}
		return (
			<>
			</>
		);
	}

	/***
	 * 月日の先頭0を省略する
	 * @param date 
	 */
	private convertZeroOmit(date: string): any {
		if (date.charAt(0) === '0') {
			return date.substring(1);
		}
		return date;
	}

	/***
	 * 税種別表示
	 * @param date 
	 */
	private convertTaxType(taxTypeCd: string): any {
		let taxType: string = '';
		if (ObjectUtil.isNotNullOrUndefined(taxTypeCd)) {
			taxType = "(" + taxTypeCd + ")";
		}
		return taxType;
	}

	/***
	 * 税種別表示
	 * @param date 
	 */
	private dispAmount(noAmountFlg: boolean, amount: number): any {
		let retAmount: string = '';
		if (noAmountFlg === false) {
			retAmount = amount.toLocaleString();
		}
		return retAmount;
	}

	/***
	 * 税種別表示
	 * @param date 
	 */
	private dispYen(noAmountFlg: boolean): any {
		let yen: string = '';
		if (noAmountFlg === false) {
			yen = "円";
		}
		return yen;
	}

	// --------------------------------------------------------------------------

}
