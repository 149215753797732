import { Contract } from "../../../../models/bss/customer/Contract";

/**
 * ネット詳細取得リクエスト
 */
export class GetNetInfoRequest {
	// public 変数	-----------------------------------------------------------
	public customerId: string;		// 顧客Id
	public ownerList: Contract[];	// オーナー契約リスト
	public buildingTypeCD: string;			// 
	public buildingLineTypeCD: string;			// 
	public befServiceCd: string;
	// ------------------------------------------------------------------------
}
