/**
 * 利用明細_顧客情報
 */
export class UsageAddressInfo {
	// public 変数	-----------------------------------------------------------
	public contractName: string;				// 契約者氏名
	public contractAddress: string;				// 契約者住所
	public billingName: string;					// 請求先氏名
	public billingAddress: string;				// 請求先住所
	// ------------------------------------------------------------------------
}
