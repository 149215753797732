
/**
 *  【MGeneral：コードタイプコード】
 */
class MypageCodeTypeConstants {
    
	/** 000~099 =========================================== */
	/** 重要事項説明 */
    IMPORTANT_EXPLANATION:string = '010';
	/** AP利用規約 */
    AP_TERMS_OF_SERVICE:string = '020';
	/** 約款 */
    CONTRACT_AGREE:string = '040';
	
	/** 100~199 =========================================== */
	/**  */
    AP_SERVICE:string = '101';
	/**  */
    AP_HIKARI_SERVICE:string = '102';
	/**  */
    KATTOBI_HIKARI_SERVICE:string = '103';
	/**  */
    TOKU_HIKARI_SERVICE:string = '104';
	/**  */
    MVNO_SUBJECT_SERVICE:string = '105';
	/**  */
	MESHWIFI_SERVICE:string = '106';
	/** 有料サービス（料金シミュでは0円判定） */
    PAID_SERVICE:string = '107';
	/** 特定の導入方式コード */
    INTRODUCTION_CODE:string = '108';
	/** AP無料コース */
    AP_FREE_CODE:string = '109';
	/** ネット10Gコース */
    NET_TEN_SERVICE:string = '110';
	/** イッツコムラーニングサービス */
    ITSCOM_LEARNING_SERVICE:string = '120';
	/** 戸賃FD対象外物件プラン */
	RENTALHOUSE_NONFD_OWNERPLAN:string = '130';
	/** 機器発送費 */
	MESHWIFI_SHIPPING:string = '150';
	/** 工事費 */
	MESHWIFI_COUNTRUCTION:string = '151';
	/** メッシュWIFIの金額 */
	MESHWIFI_AMOUNT:string = '152';
	/** ITSCOM_PHONE_オプション */
	PHONE_OPTION:string = '160';
	/** 工事費例外 */
	EXCEPT_CONSTRUCTION_FEE: string = '180';
	/** MDU1Gサービス */
	MDU1G_BUILDING_PLAN: string = '190';
	/** MDU1Gサービス */
	MDU1G_SERVICE: string = '191';
	/** 200~299 =========================================== */
	/** MVNO端末 */
    MVNO_TERMINAL:string = '210';
	/** MVNO端末 */
    MVNO_TERMINAL_COLOR:string = '211';
	/** MVNO初期費用 */
    MVNO_INQUIRY_VAL:string = '220';
	/** MVNOデータ量：音声プラン */
    MVNO_CAPACITY:string = '230';
	/** MVNOコース（音声プランの場合のみ）：価格 */
    MVNO_COURSE_PRICE:string = '235';
	/** MVNO　SIMオプション */
    MVNO_SIM_OPTIOIN:string = '236';
	/** MVNO　5G利用料金 */
    MVNO_5G_PRICE:string = '237';
	/** MVNO　WEBフィルタリング */
    MVNO_WEB_FILTERING:string = '239';
	/** MVNO　事前確認事項：約款 */
    MVNO_PRE_CONFIRM:string = '240';
	/** 消費税率 */
    SALES_TAX_RATE:string = '250';
	/** MVNOキャンペーンコード */
    MVNO_CAMPAIGN:string = '260';
	/** 端末延長保証 */
    MVNO_TERMINAL_GUARANTEE:string = '261';
	/** MVNOエスカレーション */
    MVNO_INQUIRY_ESCALATION:string = '270';
	/** MVNO留守番電話オプション */
    MVNO_RUSUBAN:string = '271';
	/** MVNOクーポン容量 */
    MVNO_COUPON_CPACITY:string = '280';

	/** 300~399（請求明細） =========================================== */
	/** 税区分 */
    TAX_TYPE:string = '300';	
	/**  TV_親契約として表示するコース名 */	
    TV_PARENT_COURSE:string = '301';	
	/**  東急でんき＆ガス_親明細にぶら下げる項目（インデント下げで表示する） */
    TOUKYU_SUB_ITEM:string = '302';	

	/** 400~499 =========================================== */
	YEAR_SERVICE:string = '400';	
}

export default new MypageCodeTypeConstants();