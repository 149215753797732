import React, { ReactNode } from 'react';
import { WprBaseViewComponent } from '../../../../../wpr-framework/view/component/WprBaseViewComponent';
import { AddTvSelect1View } from './AddTvSelect1View';
import { Typography, Grid, Table, TableHead, TableRow, TableCell, TableBody, Divider } from '@material-ui/core';
import { WprContainer } from '../../../../../wpr-framework/component/controls/WprContainer';
import { MatButton } from '../../../../../wpr-framework/component/controls/material/MatButton';
import { MatTableContainer } from '../../../../../wpr-framework/component/controls/material/MatTableContainer';
import { MatTypography } from '../../../../../wpr-framework/component/controls/material/MatTypography';
import { ProgressBar } from '../../../common/progress_bar/ProgressBar';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { WprRowInfo } from '../../../../../wpr-framework/component/props/WprRowInfo';
import { MatTableRow } from '../../../../../wpr-framework/component/controls/material/MatTableRow';
import { TvCourseListParts } from '../../parts/tv_course_list_parts/TvCourseListParts';
import { TvCourseModel } from '../../../../models/mypage/tv/TvCourseModel';
import tvIcon from '/shared/image/common/logo_iTSCOMTV.png';
import orangeIcon from '/public/next_icon_orange.png';

/**
 * テレビ追加用コンポーネント
 */
export class AddTvSelect1 extends WprBaseViewComponent {
	// コンストラクタ  -----------------------------------------------------------
	constructor(props: any) { super(new AddTvSelect1View(), props); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** テレビ追加ビュー情報 */
	public get viewInfo(): AddTvSelect1View	{ return this.view as AddTvSelect1View;	}
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * リストコントロール定義登録
	*/
	protected onRegisterListRender(): void {
		this.addListRender('TvCourseModel', null, this.onTvCourseModelRender);
	}

	/**
	 * 描画
	 */
	public onRender(): ReactNode {
		return (
			<>
			<div>
				<div className='pageCard'>
					<div className="pageHeader stepFlow">
						<ProgressBar name='progressBar' view={this.view} />
					</div>
					<div className='sub-logo'>
						<img src={tvIcon}></img>
					</div>
					<div className='main'>
						<div className='pageTitle'>
							<div className='title_left'>
								<MatTypography name='title' className='title_text' view={this.view}/>
								<Typography className='title_comment'>ご希望のコースを選択してください。</Typography>
							</div>
						</div>
						<div style={{textAlign:'right'}}>
							<a href="https://www.itscom.co.jp/service/cabletv/course/" target="_brank" style={{color:'black' ,textDecoration:'none'}}>
								<b>視聴可能なチャンネル一覧 </b>
								<img src={orangeIcon} style={{width:'20px',verticalAlign:'bottom'}}/>
							</a>
						</div>
							{this.renderList('TvCourseModel')}
						<div style = {{marginBottom: '50px'}}>
							<ul className='ul_kome attention_fontSize'>
								<li>
									上記金額にはレンタル料が含まれます。
								</li>
								<li>
									お客さまのご住所及び建物の設備状況によっては、サービスをご利用になれない場合や、ご覧になれないチャンネルや機能があります。
								</li>
								<li>
									表示されている月額は定価となります。実際の請求金額とは異なる場合があります。
								</li>
								<WprContainer mode='コース警告.表示' view={this.view}>
									<li>
										<MatTypography name={'course'} view={this.view} style={{display: 'contents', fontSize: '12px', fontWeight: '500'}}></MatTypography>
										は、600メガコースを含む2年コースまたは光お得パックをご契約の場合にお申し込みいただけます。
									</li>
								</WprContainer>
								<WprContainer mode='plus警告.表示' view={this.view}>
									<li>
										マックスplus・スタンダードplus・ミニplusは、未成年のお客さまはお申し込みいただけません。
									</li>
								</WprContainer>
							</ul>
						</div>
					</div>
					<Divider />
					<div className='pageFooter footer-space'>
						<div>
							<MatButton name='next' view={this.view} className='orangeButton orangeButton_parts' >
								次へ
								<NavigateNextIcon />
							</MatButton>
						</div>
						<div>
							<MatButton name='back' view={this.view} className='backButton' >
								<NavigateBeforeIcon />
								戻る
							</MatButton>
						</div>
					</div>


				</div>
			</div>
			</>
		);
	}
	// --------------------------------------------------------------------------

	// リスト描画  ---------------------------------------------------------------
	/**
	 * リスト名=TvCourseModel
	 */
	public onTvCourseModelRender(rowInfo: WprRowInfo): any {
		const rowData: TvCourseModel =rowInfo.rowData;
		const courseCd: string = rowData.course;
		return (
			<Grid item xs={12}>
				<TvCourseListParts row={rowInfo} view={this.view} name={courseCd}/>
			</Grid>
		);
	}
	// --------------------------------------------------------------------------
}
