import { WizardModel, WizardPostModel } from '../../../models/WizardModel';
import { ProgressBarModel, ProgressBarStopModel } from '../../../models/ProgressBarModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { MypageWizardFlow } from './../MypageWizardFlow';
import { WprBaseScopeViewInfo } from '../../../../wpr-framework/view/WprBaseScopeViewInfo';
import { ViewInfoModel } from '../../../service/models/apimodels/ViewInfoModel';
import { GetViewInfoRequest } from '../../../service/models/dto/mypagerenewal/GetViewInfoRequest';
import { MDisplay } from '../../../service/models/entity/secondary/MDisplay';
import { MGeneral } from '../../../service/models/entity/MGeneral';
import { ObjectUtil } from '../../../common/util/ObjectUtil';
import { ServiceModel } from '../../../service/models/apimodels/ServiceModel';
import { Contract } from '../../../models/bss/contract/Contract';
import { Contract as OwnerContract} from '../../../models/bss/customer/Contract';
import { GetViewInfoResponse } from '../../../service/models/dto/mypagerenewal/GetViewInfoResponse';
import { MCommon } from '../../../service/models/entity/primary/MCommon';
import { BaseService } from '../../../service/base/BaseService';
import { RegistInfoRequest } from '../../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { RegistInfoResponse } from '../../../service/models/dto/mypagerenewal/RegistInfoResponse';
import { Payment } from '../../../models/bss/customer/payment/Payment';
import { Plan } from '../../../models/bss/contract/Plan';
import MypageCodeTypeConstants from '../../../common/MypageCodeTypeConstants';
import { VIEW_DEFINE_JSON } from '../../../common/viewDefine';
import { RegistContractRequest } from '../../../service/models/dto/mypagerenewal/RegistContractRequest';
import MypageDispdivcdConstants from '../../../common/MypageDispdivcdConstants';
import { DateUtil } from '../../../common/util/DateUtil';
import { GetGradeResponse } from '../../../service/models/dto/mypagerenewal/GetGradeResponse';
import { GetConstructionCostRequest } from '../../../service/models/dto/mypagerenewal/GetConstructionCostRequest';
import { GetConstructionCostResponse } from '../../../service/models/dto/mypagerenewal/GetConstructionCostResponse';

/**
 * マイページウィザード管理クラス
 */
export class MypageWizardMngr {

	// このクラスはFWスコープモデルとして使用されています
	// ViewクラスやServiceクラスをメンバ変数で設定するとFWが処理できないので、指定しないようにしてください

	// private 変数  ------------------------------------------------------------
	private m_UrlParams: WizardPostModel				= null;		// URLのGETパラメータ
	private m_CurrentViewName: string					= null;		// 現在の画面名
	private m_MypageWizardFlowList: MypageWizardFlow[]	= null;		// MyPAGE ウィザード定義リスト
	private m_CurrentWizardFlow: MypageWizardFlow		= null;		// 現在の画面ウィザードフロー
	private m_CurrentWizardModel: WizardModel			= null;		// 現在の画面ウィザード
	private m_ViewInfo: ViewInfoModel					= null;		// 共通情報
	private m_ContractMap: Map<String, Contract>		= new Map<String, Contract>();			// Contract情報
	private m_DisplayMap: Map<String, MDisplay[]>		= new Map<String, MDisplay[]>();		// MDisplay情報
	private m_GeneralMap: Map<String, MGeneral[]>		= new Map<String, MGeneral[]>();		// MGeneral情報
	private m_CommonMap: Map<String,string[]>			= new Map<String,string[]>();		// MCommon情報
	private m_CacheData: any							= null;		// 画面入力キャッシュ情報
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** 現在のフロー */
	public get activeFlow(): MypageWizardFlow 				{ 		return this.m_CurrentWizardFlow; 					}
	// --------------------------------------------------------------------------

	// public メソッド  ----------------------------------------------------------
	/**
	 * 画面ウィザードフロー定義の設定
	 * @param view 
	 */
	public setFlowList(flowList: MypageWizardFlow[]): void {
		this.m_MypageWizardFlowList = flowList;
	}

	/**
	 * URLのGETパラメータ取得
	 */
	public getParams(): WizardPostModel {
		return this.m_UrlParams;
	}

	/**
	 * 画面ウィザードのView設定
	 * @param view 
	 */
	public setFlow(view: MypageBaseView, viewUrl: string, resMethod: (result: any) => void): void {
		// 初回の画面として、ウィザードフローを取得する
		this.terminate(view);
		this.m_UrlParams = view.getStorageItem('c_params');
		if (this.m_UrlParams == null)
			this.m_UrlParams = new WizardPostModel();
		
		const viewDefine = VIEW_DEFINE_JSON.find(view => {
			return '/' + viewUrl === view.virtualPage;
		});
		if (viewDefine == null) {
			resMethod.bind(view)(false);
			return;
		}
		let viewName: string = viewDefine.name;
		let wizardFlowName: string = null;
		this.m_MypageWizardFlowList.some((item, index) => {
			wizardFlowName = item.getWizardFlowName(viewName);
			if (wizardFlowName != null) {
				this.m_CurrentWizardFlow = item;
				view.setStorageItem('c_currentWizardFlowName', wizardFlowName);
				return true;
			}
		});
		if (this.m_CurrentWizardFlow == null) {
			view.showLogMessage('setFlow m_CurrentWizardFlow nullエラー ContractScopeInfoでFlowListへ追加していないか、開始画面定義が異なる viewName : ' + viewName);
			this.errorWizard(view);
			return;
		}
		// GetViewInfoのAPIを実行する
		this.initWizard(view, (result) => {
			resMethod.bind(view)(true);
		});
	}

	/**
	 * 画面ウィザードのView設定
	 * @param view 
	 */
	public initView(view: MypageBaseView, resMethod: (result: any) => void): void {
		this.m_CurrentWizardModel = null;

		// URLパラメータの復元
		if (this.m_UrlParams == null) {
			this.m_UrlParams = view.getStorageItem('c_params');
		}
		if (this.m_UrlParams == null) {
			this.m_UrlParams = new WizardPostModel();
		}

		// 遷移先のウィザードがあるか
		const viewDefine = VIEW_DEFINE_JSON.find(item => item.name === view.name);
		let wizardFlowName: string = null;
		this.m_MypageWizardFlowList.some((item, index) => {
			wizardFlowName = item.getWizardFlowName(view.name);
			if (wizardFlowName != null) {
				return true;
			}
		});

		// 現在実行中のウィザードフローを取得する : S_01_001Viewで設定される
		if (this.m_CurrentWizardFlow == null) {
			let currentWizardFlowName = view.getStorageItem('c_currentWizardFlowName');
			if (currentWizardFlowName == null) {
				// ウィザードなし画面→あり画面への遷移
				if (wizardFlowName)
					this.errorWizard(view, viewDefine.virtualPage);
				else {
					view.showLogMessage('initView 実行中ウィザードが設定されていない');
					this.errorWizard(view);
				}
				return;
			}
			this.m_MypageWizardFlowList.some((item, index) => {
				if (item.constructor.name === currentWizardFlowName) {
					this.m_CurrentWizardFlow = item;
					return true;
				}
			});
		}
		if (this.m_CurrentWizardFlow == null) {
			// view.showLogMessage('initView m_CurrentWizardFlow nullエラー ContractScopeInfoでFlowListへ追加していないか、開始画面定義が異なる viewName : ' + currentWizardFlowName);
			this.errorWizard(view);
			return;
		}

		// 画面更新キャッシュを取得する
		if (this.m_CacheData == null) {
			this.m_CacheData = view.getStorageItem('c_wizardCache');
		}
		if (this.m_CacheData == null) {
			this.m_CacheData = this.m_CurrentWizardFlow.getInitCacheData(this);
		}

		// （なければ）GetViewInfoのAPIを実行する
		this.initWizard(view, (result) => {

			// 中断処理
			let next: string = this.m_CurrentWizardFlow.checkFlowBreak(this, view);
			if (next != null) {
				this.nextWizardPath(view, next);
				return;
			}

			// 開いた画面がウィザードの中のどこかを確認し、正常な遷移かを確認する
			this.m_CurrentWizardFlow.getWizardFlow(this).some((item, index) => {
				if (item.viewName === view.name) {
					this.m_CurrentWizardModel = item;
					return true;
				}
			});
			// 別ウィザードへ移っている場合、キャッシュをクリアして再実行
			if (this.m_CurrentWizardModel == null) {
				// ウィザード間の遷移
				if (wizardFlowName)
					this.errorWizard(view, viewDefine.virtualPage);
				else {
					view.showLogMessage('initVie w m_CurrentWizardModel nullエラー : 別ウィザードへ移っている');
					this.errorWizard(view);
				}
				return;
			}
			let beforeWizard: WizardModel = view.getStorageItem('c_currentWizardModel');
			if (beforeWizard != null) {
				// Stopモデルの突破は可能としておく
				let beforeIdx: number = Math.floor(beforeWizard.stepNum);
				let currentIdx: number = Math.floor(this.m_CurrentWizardModel.stepNum);
				if (currentIdx == 0) {
					// printとかは許容
				} else if (beforeIdx == currentIdx) {
					// F5更新
				} else if (beforeIdx + 1 == currentIdx) {
					// 次に進んだ
				} else if (beforeIdx - 1 == currentIdx) {
					// 前に戻った
				} else {
					// ２つ以上離れた遷移をした
					view.showLogMessage('initView ２つ以上離れた遷移をしたエラー');
					this.errorWizard(view);
					return;
				}
			}
			this.m_CurrentViewName = view.name;
			view.setStorageItem('c_currentWizardModel', this.m_CurrentWizardModel);

			resMethod.bind(view)(this.m_ViewInfo);
		});
	}

	/**
	 * MyPAGE画面表示情報の取得
	 */
	public getViewInfo(): ViewInfoModel {
		return this.m_ViewInfo;
	}

	/**
	 * MDisplayの取得
	 */
	public getMDisplay(view: MypageBaseView, dispdivcd: string, namecd: string): MDisplay {
		const data = this.m_DisplayMap.get(dispdivcd + ',' + namecd);
		if (ObjectUtil.isNullOrUndefined(data)) {
			view.showLogMessage('getMDisplay データが見つからないエラー ' + 'dispdivcd:' + dispdivcd + ', namecd:' + namecd);
			return null;
		}
		if (data.length = 1)
			return data[0];
		if (data.length > 0)
			view.showLogMessage('getMDisplay 複数データが見つかったエラー ' + 'dispdivcd:' + dispdivcd + ', namecd:' + namecd);
		else
			view.showLogMessage('getMDisplay データが見つからないエラー ' + 'dispdivcd:' + dispdivcd + ', namecd:' + namecd);
		return null;
	}

	/**
	 * MDisplayの一覧取得
	 */
	public getMDisplayList(view: MypageBaseView, dispdivcd: string, namecd: string): MDisplay[] {
		const data = this.m_DisplayMap.get(dispdivcd + ',' + namecd);
		if (ObjectUtil.isNullOrUndefined(data)) {
			view.showLogMessage('getMDisplayList データが見つからないエラー ' + 'dispdivcd:' + dispdivcd + ', namecd:' + namecd);
			return null;
		}
		if (data.length > 0)
			return data;
		view.showLogMessage('getMDisplayList データが見つからないエラー ' + 'dispdivcd:' + dispdivcd + ', namecd:' + namecd);
		return null;
	}

	/**
	 * MGeneralの取得
	 */
	public getMGenral(view: MypageBaseView, codeTypeCd: string, generalCd: string): MGeneral {
		const data = this.m_GeneralMap.get(codeTypeCd + ',' + generalCd);
		if (ObjectUtil.isNullOrUndefined(data)) {
			view.showLogMessage('getMGenral データが見つからないエラー ' + 'codeTypeCd:' + codeTypeCd + ', generalCd:' + generalCd);
			return null;
		}
		if (data.length == 1)
			return data[0];
		if (data.length > 0)
			view.showLogMessage('getMGenral 複数データが見つかったエラー ' + 'codeTypeCd:' + codeTypeCd + ', generalCd:' + generalCd);
		else
			view.showLogMessage('getMGenral データが見つからないエラー ' + 'codeTypeCd:' + codeTypeCd + ', generalCd:' + generalCd);
		return null;
	}

	/**
	 * MGeneralの一覧取得
	 */
	public getMGenralList(view: MypageBaseView, codeTypeCd: string, generalCd: string): MGeneral[] {
		const data = this.m_GeneralMap.get(codeTypeCd + ',' + generalCd);
		if (ObjectUtil.isNullOrUndefined(data)) {
			view.showLogMessage('getMGenralList データが見つからないエラー ' + 'codeTypeCd:' + codeTypeCd + ', generalCd:' + generalCd);
			return null;
		}
		if (data.length > 0)
			return data;
		view.showLogMessage('getMGenralList データが見つからないエラー ' + 'codeTypeCd:' + codeTypeCd + ', generalCd:' + generalCd);
		return null;
	}

	/**
	 * MCommonの一覧取得
	 */
	public getMCommonList(view: MypageBaseView, category1: string, category2: string, category3: string, category4: string, showError = true): MCommon[] {
		if (ObjectUtil.isNullOrUndefined(this.m_ViewInfo.commonList)) {
			view.showLogMessage('getMCommonList データが見つからないエラー ' + 'this.m_ViewInfo.commonList');
			return null;
		}
		const list = this.m_ViewInfo.commonList;
		const data = list.filter(item => 
								item.category1 === category1 
								&& (category2? item.category2 === category2: true) 
								&& (category3? item.category3 === category3: true) 
								&& (category4? item.category4 === category4: true)
							)
		if (ObjectUtil.isNullOrUndefined(data)) {
			if (showError)
				view.showLogMessage('getMCommonList データが見つからないエラー ' + 'category1:' + category1 + 'category2:' + category2 + 'category3:' + category3 + 'category4:' + category4);
			return null;
		}
		if (data.length > 0)
			return data[0].commonList;
		if (showError)
			view.showLogMessage('getMCommonList データが見つからないエラー ' + 'category1:' + category1 + 'category2:' + category2 + 'category3:' + category3 + 'category4:' + category4);
		return null;
	}

	/**
	 * commonValueが一致するMCommonを取得
	 */
	 public getMCommonByValue(view: MypageBaseView, category1: string, category2: string, category3: string, category4: string, commonValue: string): MCommon {
		if (ObjectUtil.isNullOrUndefined(this.m_ViewInfo.commonList)) {
			view.showLogMessage('getMCommonByValue データが見つからないエラー ' + 'this.m_ViewInfo.commonList');
			return null;
		}
		const list = this.m_ViewInfo.commonList;
		const dataList = list.filter(item => 
								item.category1 === category1 
								&& (category2? item.category2 === category2: true) 
								&& (category3? item.category3 === category3: true) 
								&& (category4? item.category4 === category4: true)
							)
		if (ObjectUtil.isNullOrUndefined(dataList)) {
			view.showLogMessage('getMCommonByValue データが見つからないエラー ' + 'category1:' + category1 + 'category2:' + category2 + 'category3:' + category3 + 'category4:' + category4 + 'commonValue:' + commonValue);
			return null;
		}
		for (const data of dataList) {
			const dataValue = data.commonList.filter(item => 
				item.commonValue === commonValue
			)
			if (ObjectUtil.isNotNullOrUndefined(dataValue) && (dataValue.length > 0)) {
				return dataValue[0];
			}
		}
		return null;
	}

	/**
	 * service一覧取得
	 */
	public getServiceList(view: MypageBaseView, dispdivcd: string): ServiceModel[] {
		if (ObjectUtil.isNullOrUndefined(this.m_ViewInfo.serviceList)) {
			view.showLogMessage('getServiceList データが見つからないエラー ' + 'this.m_ViewInfo.serviceList');
			return null;
		}
		const data = this.m_ViewInfo.serviceList.get(dispdivcd);
		if (ObjectUtil.isNullOrUndefined(data)) {
			view.showLogMessage('getServiceList データが見つからないエラー ' + 'dispdivcd:' + dispdivcd);
			return null;
		}
		if (data.length > 0)
			return data;
		view.showLogMessage('getServiceList データが見つからないエラー ' + 'dispdivcd:' + dispdivcd);
		return null;
	}

	/**
	 * Contractの取得
	 */
	// public getContract(view: MypageBaseView, contractId: string): Contract {
	// 	const customerId: string = view.getCustomerId();
	// 	const data = this.m_ContractMap.get(customerId + contractId);
	// 	if (ObjectUtil.isNullOrUndefined(data)) {
	// 		view.showLogMessage('getContract データが見つからないエラー ' + 'contractId:' + contractId);
	// 		return null;
	// 	}
	// 	return data;
	// }

	
	/**
	 * Contractの取得
	 */
	public getContract(view: MypageBaseView, contractId: string): Contract {
		const data = this.m_ContractMap.get(contractId);
		if (ObjectUtil.isNullOrUndefined(data)) {
			view.showLogMessage('getContract データが見つからないエラー ' + 'contractId:' + contractId);
			return null;
		}
		return data;
	}

	/**
	 * 税込計算
	 */
	public getTaxFee(view: MypageBaseView, amount: string): string {
		const data = this.getMGenral(view, MypageCodeTypeConstants.SALES_TAX_RATE,'');
		if (data) {
			const tax = data.num3;
			if (!Number.isNaN(Number(amount))) {
				const totalFee = Math.floor(Number(amount) * tax);
				return String(totalFee);
			}
			return amount + ' * ' + tax
		}
		return amount + ' * ???';
	}

	/**
	 * お得パック申込み判定
	 */
	public showOtokuPlan(view: MypageBaseView, payment: Payment, planList: Plan[]): boolean {
		if (payment.paymentTypeCD !== '3') 
			return false;
		if (ObjectUtil.isNotNullOrUndefined(this.getOtokuContract(view, planList))) 
			return false;
		const contractList = this.getViewInfo().contractModel.contractList;
		// 既存契約レンタルフラグ判定
		if (contractList && contractList.length > 0) {
			for (const contract of contractList) {
				if (contract.equipment[0].rentalFlag === '0')
					return false
			}
		}
		return true;
	}

	/**
	 * お得パック名称取得
	 */
	public getOtokuPlanName(view: MypageBaseView, planList: Plan[]): MDisplay {
		const otokuPack = this.getOtokuContract(view, planList);
		if (ObjectUtil.isNotNullOrUndefined(otokuPack)) {
			for (const mDisplay of this.getMDisplayList(view, 'CD0035', '')) {
				if (otokuPack.planCD === mDisplay.namecd)
					return mDisplay;
			}
		}
		return null;
	}

	/**
	 * 物件プラン取得
	 * 
	 */
	public getBuildingPlan(view: MypageBaseView, ownerList: OwnerContract[]): MCommon {
		let buildingPlan = new MCommon(null);
		if (ObjectUtil.isNotNullOrUndefined(ownerList) && ownerList.length > 0) {
			let serviceCd = '';
			let typeCd = '';
			const ownerIhList = this.getMCommonList(view, 'IH', 'OWNER_SCD', null, null);
			for (const owner of ownerList) {
				if (serviceCd === '') {
					serviceCd = owner.serviceCD;
					typeCd = owner.contractTypeCD;
				}
				// IHバルクとIHバルクではない
				 else if (this.isCommonValue(ownerIhList, serviceCd) && !this.isCommonValue(ownerIhList, owner.serviceCD)) {
					serviceCd = owner.serviceCD;
					typeCd = owner.contractTypeCD;
				}
				// 両方IHバルク
				 else if (this.isCommonValue(ownerIhList, serviceCd) && this.isCommonValue(ownerIhList, owner.serviceCD)) {
					serviceCd = owner.serviceCD;
					typeCd = owner.contractTypeCD;
				}
				// 両方IHバルクではない
				 else if (!this.isCommonValue(ownerIhList, serviceCd) && !this.isCommonValue(ownerIhList, owner.serviceCD)) {
					serviceCd = owner.serviceCD;
					typeCd = owner.contractTypeCD;
				}
			}
			// 物件プラン判定
			if (typeCd) {
				const buildingPlanList = this.getMCommonList(view, 'building_plan', null, null, null, false);
				const plan = buildingPlanList.filter(data => data.category2 === 'CTCD_' + typeCd);
				if (ObjectUtil.isNullOrUndefined(plan) || plan.length === 0)
					buildingPlan.commonValue = '0';
				else
					buildingPlan = plan[0];
			}
		}
		else 
			buildingPlan.commonValue = '0';
		return buildingPlan;
	}

	/**
	 * エラー取得
	 */
	public setErrorReqDateItem(list: { flag: boolean, dispVal: string }[]): string {
		let itemList: string[] = new Array();
		list.forEach(val => {
			if (val.flag) itemList.push(val.dispVal);
		});
		let itemStr: string = '';
		for (let item of itemList) {
			if (itemStr != '') itemStr += '、';
			itemStr += item;
		}
		return itemStr;
	}

	/**
	 * 希望日時入力チェック
	 */
	public isNotEmpty(reqTime: string, reqDate: string): boolean {
		const valFlg1 = ObjectUtil.isNullOrEmptyStr(reqTime);
		const valFlg2 = ObjectUtil.isNullOrEmptyStr(reqDate);
		return (valFlg1 === valFlg2);
	}

	/**
	 * 希望日時　MAX
	 * @returns 
	 */
	public getMaxReqDate(): string {
		const max = '99991231';
		return DateUtil.convertToDateWithHyphen(max);
	}
		
	/**
	 * 希望日時　MIN
	 * @returns 
	 */
	public getMinReqDate(): string {
		// todayから8日以降がOK
		const today: string = DateUtil.getTodayStr();
		const max: string = DateUtil.getPlusDate(today, 7);
		return DateUtil.convertToDateWithHyphen(max);
	}
		
	public convertToDate(dateStr: string): string {
		const date = new Date(dateStr);
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const day = date.getDate();
		return  year + '年' + month + '月' + day + '日';
	}

	/**
	 * マイグレ判定
	 * コース変更用
	 * 変更前HFCコースで変更後FTTHコース
	 */
	public modMigration(view: MypageBaseView, grade: GetGradeResponse) {
		if (grade.befCourseGrade && grade.aftCourseGrade) {
			if (!grade.befCourseGrade.ftthFlg && grade.aftCourseGrade.ftthFlg)
				return true;
			else 
				return false;
		}
	}

	/**
	 * マイグレ判定
	 * コース追加用
	 * 物件回線種別FTTHでHFCサービスを利用している
	 */
	public isMigration(view: MypageBaseView):boolean {
		const customer = this.getViewInfo().customer;
		const contractList = this.getViewInfo().contractModel.contractList;
		// 契約情報あり かつ 物件回線種別【FTTH】
		if (contractList && contractList.length > 0 && customer.building[0].buildingLineTypeCD === '2') {
			const hfcCourseList = this.getViewInfo().serviceLineList.filter(row => row.linetype === 'HFC');
			for (const contract of contractList) {
				const hfcCourse = hfcCourseList.filter(data => data.servicecd === contract.serviceCD);
				if (hfcCourse && hfcCourse.length > 0)
					return true;
			}
			return false;
		}
		return false;
	}

	/**
	 * 有料サービス判定 ※NHK、電ガス
	 * (料金シミュで0円判定だが、有料サービス)
	 * @returns true:有料　false:無料
	 */
	public isPaidService(view: MypageBaseView): boolean {
		const contractModel = this.getViewInfo().contractModel;
		if (contractModel && contractModel.contractList && contractModel.contractList.length > 0) {
			const paidServiceList = this.getMGenralList(view, MypageCodeTypeConstants.PAID_SERVICE, '');
			let paidFlg = false;
			for (const paidService of paidServiceList) {
				for (const contract of contractModel.contractList) {
					if (paidService.generalCd === contract.serviceCD)
						paidFlg = true;
				}
			}
			if (paidFlg)
				return true;
			else
				return false;
		}
		return false;
	}
	
	/**
	 * 工事費取得
	 * freeFlg ※メッシュWi-Fi以外、基本false
	 * provChangeFlg 提供方法(購入→レンタル)の場合、true
	 */
	public getConstructionCost(view: MypageBaseView, req: GetConstructionCostRequest, grade: GetGradeResponse, 
		tokotonAddFlg: boolean, provChangeFlg: boolean,
		resMethod: (result: GetConstructionCostResponse) => void): void {
		const customer = this.getViewInfo().customer;
		if (customer.building[0].buildingTypeCD === '1')
			req.housingKbnCd = '2';
		else if (customer.building[0].buildingTypeCD === '2'|| customer.building[0].buildingTypeCD === '3')
			req.housingKbnCd = '1';
		if (grade && !req.applicationDetailsCd)
			req.applicationDetailsCd = this.getUpDown(grade, provChangeFlg);
		// とことんサポート判定
		if (tokotonAddFlg)
			req.tokotonSuportFlg = true;
		else {
			req.tokotonSuportFlg = false;
			if (ObjectUtil.isNotNullOrUndefined(this.getViewInfo().contractModel.contractList) && this.getViewInfo().contractModel.contractList.length > 0) {
				if (req.constructionCategoryCd === '01' || req.constructionCategoryCd === '02' || req.applicationDetailsCd === '08') { // 移設、増設の場合、機器変更(提供方法)のみ
					const contractList = this.getViewInfo().contractModel.contractList.filter(row => row.statusCD === '1');
					const tokotonSupport = this.getMDisplayList(view, MypageDispdivcdConstants.TOKOTON_SUPPORT_COURSE_NAME, '');
					if (contractList && contractList.length > 0) {
						const service = contractList.filter(data => data.serviceCD === tokotonSupport[0].namecd);
						if (service && service.length > 0)
							req.tokotonSuportFlg = true;
					}
				}
			}
		}
		view.getConstructionCost(view, req, (result: GetConstructionCostResponse) => {
			resMethod.bind(view)(result);
		});
	}

	/**
	 * 戸建賃貸FD誘導判定
	 */
	public isRentalHouseFd(view: MypageBaseView) {
		let fdFlg = true;
		const ownerContractList = this.getViewInfo().ownerContractList;
		if (ownerContractList && ownerContractList.length > 0) {
			for (const ownerContract of ownerContractList) {
				const data = this.getMGenralList(view, MypageCodeTypeConstants.RENTALHOUSE_NONFD_OWNERPLAN, '').filter(data => data.generalCd === ownerContract.serviceCD);
				if (data && data.length > 0)
					fdFlg = false
			}
		}
		return fdFlg;
	}

	/**
	 * 除外サービス取得
	 * 
	 */
	public getExcludeService(view: MypageBaseView): string[] {
		let excludeServiceList: string[] = new Array();
		const ownerList = this.getViewInfo().ownerContractList;
		const MDU1GServiceList = this.getMGenralList(view, MypageCodeTypeConstants.MDU1G_SERVICE, '');
		let dispFlg = false;
		if (ObjectUtil.isNotNullOrUndefined(ownerList) && ownerList.length > 0) {
			// 1G対応済み物件ではない場合、非表示とする1G対応サービスをリストに追加
			for (const owner of ownerList) {
				const data = this.getMGenralList(view, MypageCodeTypeConstants.MDU1G_BUILDING_PLAN, '').filter(data => data.generalCd === owner.serviceCD);
				if (data && data.length > 0) {
					dispFlg = true;
				}
			}
		}
		if (!dispFlg) {
			for (const service of MDU1GServiceList) {
				excludeServiceList.push(service.generalCd);
			}
		}
		return excludeServiceList;
	}

	/**
	 * キャッシュの取得
	 */
	public getCache(): any {
		return this.m_CacheData;
	}

	/**
	 * 画面ウィザードを戻る
	 */
	public goBack(view: MypageBaseView): void {
		if (this.m_CurrentViewName == null) {
			view.showLogMessage('goBack initViewしてないエラー');
			this.errorWizard(view);
			return;
		}
		if (this.m_CurrentWizardFlow == null) {
			view.showLogMessage('goBack m_CurrentWizardFlow nullエラー');
			this.errorWizard(view);
			return;
		}
		// キャッシュ更新
		if (this.m_CacheData != null) {
			view.setStorageItem('c_wizardCache', this.m_CacheData);
		}
		// 画面遷移
		let flow: WizardModel[] = this.m_CurrentWizardFlow.getWizardFlow(this);
		flow.some((item, index) => {
			// stepNumが同一のまま画面遷移が可能なように対応
			// if (item.stepNum == this.m_CurrentWizardModel.stepNum - 1) {
			if (item.viewName === this.m_CurrentWizardModel.viewName) {
				this.nextWizard(view, flow[index - 1]);
				return true;
			}
		});
	}
	
	/**
	 * 画面ウィザードを進める
	 */
	public goNext(view: MypageBaseView): void {
		if (this.m_CurrentViewName == null) {
			view.showLogMessage('goNext initViewしてないエラー');
			this.errorWizard(view);
			return;
		}
		if (this.m_CurrentWizardFlow == null) {
			view.showLogMessage('goNext m_CurrentWizardFlow nullエラー');
			this.errorWizard(view);
			return;
		}
		// キャッシュ更新
		if (this.m_CacheData != null) {
			view.setStorageItem('c_wizardCache', this.m_CacheData);
		}
		//次の遷移先取得
		let flow: WizardModel[] = this.m_CurrentWizardFlow.getWizardFlow(this);
		let currentFlow: WizardModel =  flow.filter((item) => item.viewName === this.m_CurrentWizardModel.viewName)[0];
		let nextFlow: WizardModel =  flow.filter((item) => item.stepNum === currentFlow.stepNum + 1)[0];
		// Wizard完了イベント
		let stepNum = 0;
		for (const data of this.m_CurrentWizardFlow.getWizardFlow(this)) {
			if (data.stepNum > stepNum)
				stepNum = data.stepNum
		}
		if (this.m_CurrentWizardModel.stepNum == stepNum - 1) {
			let req: RegistContractRequest = this.m_CurrentWizardFlow.getRegistDataApiModel(this, view);
			if (req) {
				view.registContract(view, req, (result: RegistInfoResponse) => {
					if (result && result.status === BaseService.SERVICE_SUCCESS_STATUS) {
						let reqCommon: RegistInfoRequest = this.m_CurrentWizardFlow.getRegistApiModel(this, view, result.resultCd);
						if (reqCommon) {
							reqCommon.wizardFlowName = view.getCookie('c_currentWizardFlowName');
							view.registInfo(view, reqCommon, (result: RegistInfoResponse) => {
								if (result && result.status === BaseService.SERVICE_SUCCESS_STATUS) 
									this.nextWizard(view, nextFlow);
							});
						}
						else 
							this.nextWizard(view, nextFlow);
					}
				});
			}
			else {
				let reqCommon: RegistInfoRequest = this.m_CurrentWizardFlow.getRegistApiModel(this, view);
				if (reqCommon) {
					reqCommon.wizardFlowName = view.getCookie('c_currentWizardFlowName');
					view.registInfo(view, reqCommon, (result: RegistInfoResponse) => {
						if (result && result.status === BaseService.SERVICE_SUCCESS_STATUS) 
							this.nextWizard(view, nextFlow);
					});
				}
				else 
					this.nextWizard(view, nextFlow);
			}
		}
		else 
			this.nextWizard(view, nextFlow);
	}
	
	/**
	 * ステップバー情報セット
	 */
	public getProgressModelList(view: MypageBaseView): ProgressBarModel[] {
		if (this.m_CurrentViewName == null) {
			view.showLogMessage('getProgressModelList initViewしてないエラー');
			this.errorWizard(view);
			return;
		}
		if (this.m_CurrentWizardFlow == null) {
			view.showLogMessage('getProgressModelList m_CurrentWizardFlow nullエラー');
			this.errorWizard(view);
			return;
		}
		const list: ProgressBarModel[] = new Array();
		const idxList: number[] = new Array();
		let hasNext: boolean = false;
		this.m_CurrentWizardFlow.getWizardFlow(this).forEach((item, index) => {
			let itemIdx: number = item.stepNum;
			let currentIdx: number = this.m_CurrentWizardModel.stepNum;
			// stepNumが同一のまま画面遷移が可能なように対応
			if (idxList.indexOf(itemIdx) > 0)
				return;
			idxList.push(itemIdx);
			if (itemIdx == 0) {
				// 0はステップバーに表示しない
			} else if (itemIdx < currentIdx) {
				this.setProgressBar(item, 'complete', list);
			} else if (itemIdx == currentIdx) {
				this.setProgressBar(item, 'current', list);
			} else if (hasNext == false && itemIdx > currentIdx) {
				this.setProgressBar(item, 'next', list);
				hasNext = true;
			} else {
				this.setProgressBar(item, 'not', list);
			}
		});
		return list;
	}
	
	/**
	 * 画面プログレスバーの設定
	 * @param view 
	 */
	private setProgressBar(item: WizardModel, status: 'current' | 'next' | 'complete' | 'not', list: ProgressBarModel[]): void {
		let isDigi: boolean = item.stepNum % 1 !== 0;
		// 小数点はStopモデル
		if (isDigi) {
			const stopModel: ProgressBarStopModel = new ProgressBarStopModel(status);
			let itemIdx: number = Math.floor(item.stepNum);
			let progressBar:ProgressBarModel = list.find(progressBar => {
				return Number(progressBar.stepNum) == itemIdx;
			});
			if (progressBar.stopModel == null)
				progressBar.stopModel = new Array();
			progressBar.stopModel.push(stopModel);
		} else {
			const model: ProgressBarModel = new ProgressBarModel(String(item.stepNum), item.title, status, null)
			list.push(model);
		}
	}

	/**
	 * 管理終了
	 */
	public terminate(view: WprBaseScopeViewInfo): void {
		view.removeStorageItem('c_currentWizardFlowName');
		view.removeStorageItem('c_currentWizardModel');
		view.removeStorageItem('c_wizardCache');
		view = null;
		this.m_CurrentWizardFlow = null;
		this.m_CurrentWizardModel = null;
		this.m_ViewInfo = null;
		this.m_CacheData = null;
	}
	// --------------------------------------------------------------------------

	// private メソッド  ---------------------------------------------------------
	/**
	 * 画面ウィザードの初期化
	 */
	private initWizard(view: MypageBaseView, resMethod: (result: any) => void): void {
		const viewDefine = VIEW_DEFINE_JSON.find(viewDifine => {
			return view.name === viewDifine.name;
		});
		if (ObjectUtil.isNotNullOrUndefined(viewDefine.noMenuFlg) && viewDefine.noMenuFlg === '1') {
			resMethod.bind(view)(this.m_ViewInfo);
			return;
		}
		// 必要なAPI情報を取得する
		let req: GetViewInfoRequest = this.m_CurrentWizardFlow.getWizardApiModel(this, view);
		if (this.m_ViewInfo == null) {
			if(!req.generalList) 
				req.generalList = new Array();
			req.generalList.push(new MGeneral(MypageCodeTypeConstants.SALES_TAX_RATE));	// 消費税率
			view.getViewInfo(view, req, (result: GetViewInfoResponse) => {
				if (result) {
					this.m_ViewInfo = result.viewModel;
					this.m_ViewInfo = this.m_CurrentWizardFlow.updateViewInfo(this, view);
					// Contract情報
					this.m_ContractMap = new Map<String, Contract>();
					if (result.viewModel.contractModel && result.viewModel.contractModel.contractList) {
						for (const data of result.viewModel.contractModel.contractList) {
							this.m_ContractMap.set(data.contractID.slice(-5), data);
						}
					}
					// MDisplay情報
					this.m_DisplayMap = new Map<String, MDisplay[]>();
					if (result.viewModel.displayModelList) {
						for (const data of result.viewModel.displayModelList) {
							if (!data.namecd)
								data.namecd = '';
							this.m_DisplayMap.set(data.dispdivcd + ',' + data.namecd, data.displayList);
						}
					}
					// MGeneral情報
					this.m_GeneralMap = new Map<String, MGeneral[]>();
					if (result.viewModel.generalModelList) {
						for (const data of result.viewModel.generalModelList) {
							if (!data.general_cd)
								data.general_cd = '';
							this.m_GeneralMap.set(data.code_type_cd + ',' + data.general_cd, data.generalList);
						}
					}
					if(result.viewModel.serviceList) {
						result.viewModel.serviceList = new Map<string, any>(Object.entries(result.viewModel.serviceList));
					}
				} else {
					//　開発環境でのみ、サーバエラー時にここへ到達する
					this.m_ViewInfo = new ViewInfoModel();
				}
				resMethod.bind(view)(this.m_ViewInfo);
			});
		} else {
			resMethod.bind(view)(this.m_ViewInfo);
		}
	}

	/**
	 * 次のウィザードに移動する
	 * @param view
	 * @param changeWizard
	 */
	private nextWizard(view: MypageBaseView, nextWizard: WizardModel): void {
		const host = location.host;
		// 開発環境ではメニュー画面へ戻る
		if (host.includes("localhost") && nextWizard.path.endsWith('Srvlist/Continfo_list/'))
			// view.pushPath('/menu');
			location.href = '/menu';
		else if (nextWizard.path.startsWith('http'))
			location.href = nextWizard.path;
		else
			view.pushPath(nextWizard.path, nextWizard.params, nextWizard.isValid, nextWizard.useViewData);
	}
	private nextWizardPath(view: MypageBaseView, nextPath: string): void {
		const host = location.host;
		// 開発環境ではメニュー画面へ戻る
		if (host.includes("localhost") && nextPath.endsWith('Srvlist/Continfo_list/'))
			// view.pushPath('/menu');
			location.href = '/menu';
		else if (nextPath.startsWith('http'))
			location.href = nextPath;
		else
			view.pushPath(nextPath);
	}

	/**
	 * 異常な画面遷移
	 */
	private errorWizard(view: MypageBaseView, path?: string) {
		this.terminate(view);
		// view.showLogMessage('errorWizard 異常な画面遷移エラー');
		const host = location.host;
		// 開発環境ではエラーメッセージ表示してからエラーに移る
		if (host.includes("localhost")) {
			if (path) 
				this.resetFlow(view, path);
			else {
				setTimeout(() => {
					view.pushPath('/error');
				}, 2500);
			}
		}
		else {
			if (path)
				this.resetFlow(view, path);
			else
				view.pushPath('/error');
		}
	}

	/**
	 * 画面ウィザードのView再設定
	 * パス連携がある場合、再取得
	 */
	private resetFlow(view: MypageBaseView, path?: string) {
		this.setFlow(view,path.substring(1), (result) => {
			if (result)
				view.replacePath(path + '?reload');
			else
				view.showLogMessage('viewDefineが定義されていない');
		});
	}

	/**
	 * お得パック契約判定
	 * @param planList 
	 */
	private getOtokuContract(view: MypageBaseView, planList: Plan[]) : Plan {
		let otokuList: MCommon[] = this.getMCommonList(view, 'otoku', 'plan_cd', null, null);
		if (ObjectUtil.isNullOrUndefined(planList) || planList.length === 0) {
			return null;
		}
		for (const otoku of otokuList) {
			for (const plan of planList) {
				if (plan.planCD === otoku.commonValue) 
					return plan;
			}
		}
		return null;
	}

	/**
	 * オーナー契IH判定
	 * @param mCommonList 
	 * @param value 
	 * @returns 
	 */
	private isCommonValue(mCommonList: MCommon[], value: string): boolean {
		const data = mCommonList.filter(item =>
			item.commonValue === value)
		if (ObjectUtil.isNotNullOrEmptyArray(data)) {
			return true;
		}
		return false;
	}

	/**
	 * コース・機器判定
	 * up/down
	 * @param grade 
	 * @returns 
	 * 備考：値が小さい方がグレードが高い
	 */
	private getUpDown(grade: GetGradeResponse, provChangeFlg: boolean): string {
		if (grade.befCourseGrade && grade.aftCourseGrade) {
			let code: string;
			// コースUp
			if (Number(grade.befCourseGrade.courseGrade) > Number(grade.aftCourseGrade.courseGrade)) {
				// 機器：あり → あり
				if (grade.befEquipmentGrade && grade.aftEquipmentGrade) {
					if (Number(grade.befEquipmentGrade.terminalGrade) > Number(grade.aftEquipmentGrade.terminalGrade))
						code = '03';
					else if (Number(grade.befEquipmentGrade.terminalGrade) < Number(grade.aftEquipmentGrade.terminalGrade))
						code = '05';
					// 同グレード、提供方法：購入→レンタル
					else if (provChangeFlg)
						code = '08';
				}
				// 機器：なし → あり
				else if (!grade.befEquipmentGrade && grade.aftEquipmentGrade)
					code = '03';
				// 機器：あり → なし
				else if (grade.befEquipmentGrade && !grade.aftEquipmentGrade)
					code = '05';
			}
			// コースDown
			else if (Number(grade.befCourseGrade.courseGrade) < Number(grade.aftCourseGrade.courseGrade)) {
				// 機器：あり → あり
				if (grade.befEquipmentGrade && grade.aftEquipmentGrade) {
					if (Number(grade.befEquipmentGrade.terminalGrade) > Number(grade.aftEquipmentGrade.terminalGrade))
						code = '04';
					else if (Number(grade.befEquipmentGrade.terminalGrade) < Number(grade.aftEquipmentGrade.terminalGrade))
						code = '06';
					// 同グレード、提供方法：購入→レンタル
					else if (provChangeFlg)
						code = '08';
				}
				// 機器：なし → あり
				else if (!grade.befEquipmentGrade && grade.aftEquipmentGrade)
					code = '04';
				// 機器：あり → なし
				else if (grade.befEquipmentGrade && !grade.aftEquipmentGrade)
					code = '06';
			}
			return code;
		}
		return null;
	}

	// --------------------------------------------------------------------------
}
