import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { BssConstants } from '../../../common/constants/BssConstants';
import { ContractPlanModel } from '../../../service/models/apimodels/ContractPlanModel';
import { NameChangeAppModel } from '../../../service/models/apimodels/NameChangeAppModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { CompleteModel } from '../../../view/common/common_complete/models/CompleteModel';
import { NameChangeApiModel, NameChangeWizardFlow } from '../../wizard/NameChangeWizardFlow';
import { ObjectUtil } from '../../../common/util/ObjectUtil';

/**
 * 新名義人受付完了
 */
export class NameChangeNewCompleteView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('NameChangeNewCompleteView'); }
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ウィザード定義 */
	public get mngr(): MypageWizardMngr 					{ return this.m_MypageWizardMngr; }
	// --------------------------------------------------------------------------
	
	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr = null;	// 画面ウィザード管理クラス
	@WprModel('NameChangeAppModel')
	private m_EntryInfo: NameChangeAppModel = null;			// 名義変更申込み情報モデル
	@WprModel('CompleteModel')
	private m_CompleteModel: CompleteModel	= null;	// 完了情報モデル

	private m_ContractModel: ContractPlanModel = null;		// 契約情報 

	// --------------------------------------------------------------------------

	// private変数  ---------------------------------------------------------------
	private m_CustomerName: string							= null;	// 顧客名
	private m_NameChangeApiModel = new NameChangeApiModel();
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('user', 'ユーザー', 'CompleteModel');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('toQuestionnaire', this.onToQuestionnaire);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href)
			this.m_EntryInfo = this.m_MypageWizardMngr.getCache().nameChangeAppInfo;
			const nameChangeWizard = new NameChangeWizardFlow();
			// ケーブルプラス電話判定 '5'または'15'
			this.m_ContractModel = this.m_MypageWizardMngr.getViewInfo().contractModel;
			if (ObjectUtil.isNotNullOrUndefined(this.m_ContractModel.contractList) && this.m_ContractModel.contractList.length > 0) {
				for(const contract of this.m_ContractModel.contractList) {
					if((contract.serviceTypeCD === BssConstants.BSS_CONTRACT_SERVICETYPECD_KDDI) 
						|| (contract.serviceTypeCD === BssConstants.BSS_CONTRACT_SERVICETYPECD_PHONEF)) {
						this.setViewMode('ケーブルプラス電話', '加入');
						break;
					}
				}
			}
			this.m_CompleteModel = new CompleteModel();
			const customerInfo = this.m_MypageWizardMngr.getViewInfo().customer;
			if (customerInfo.sexCD === '3' || customerInfo.sexCD === '')
				this.m_CustomerName = customerInfo.corporateName;
			else
				this.m_CustomerName = customerInfo.surName + ' ' + customerInfo.givenName;
			this.m_CompleteModel.user = this.m_CustomerName + 'さん';
			this.setViewMode('NHK', this.m_NameChangeApiModel.isNhk(this));
			this.setViewMode('WOWOW', this.m_NameChangeApiModel.isWowwow(this));
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------
	/**
	 * アンケート
	 */
	public onToQuestionnaire(param: any): void {
		window.open('https://questant.jp/q/B7KS5E4S?id=' + this.m_EntryInfo.customerId);
	}
}
