import { WprDI_ScopeModel } from '../../../../wpr-framework/view/di/WprDI_ScopeModel';
import { MypageBaseView } from '../../base/MypageBaseView';
import { ProgressBarData } from '../../common/progress_bar/ProgressBarView';
import { MypageWizardMngr } from '../../wizard/models/MypageWizardMngr';
import { WprModel } from '../../../../wpr-framework/view/deco/WprModel';
import { TerminalService } from '../../../service/TerminalService';
import { SetMypInstallTerminalRequest } from '../../../service/models/dto/mypagerenewal/SetMypInstallTerminalRequest';
import { TerminalList } from '../../../models/iss/SetMypInstallTerminal/TerminalList';
import MypageErrorConstants from '../../../common/MypageErrorConstants';
import { CommonService } from '../../../service/CommonService';
import { RegistInfoRequest } from '../../../service/models/dto/mypagerenewal/RegistInfoRequest';
import { MAIL_CONSTANTS } from '../../../common/MyPageMailIdConstants';
import { InquiryMailInfo } from '../../../service/models/apimodels/InquiryMailInfo';

/**
 * 端末登録
 */
export class TerminalRegistView extends MypageBaseView {
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('TerminalRegistView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;			// 画面ウィザード管理クラス
	@WprModel('TerminalList')
	private m_TerminalListModel: TerminalList	= null;	// 端末登録
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_TerminalService: TerminalService	= new TerminalService();	// 端末登録サービス
	public m_CommonService: CommonService	= new CommonService();	// 共通サービス
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addConfig('terminal_id', '機器番号', 'TerminalList');
	}

	/**
	 * アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			this.m_TerminalListModel = this.m_MypageWizardMngr.getCache().m_TerminalListModel;
			this.m_TerminalListModel.terminal_id = this.m_MypageWizardMngr.getParams().tid;
			this.m_TerminalListModel.stock_type_cd = this.m_MypageWizardMngr.getParams().stcd;
			this.m_TerminalListModel.terminal_type_cd = this.m_MypageWizardMngr.getParams().ttcd;
			this.refreshView();
		});
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 次へ         <NavigateNextIcon />
	 */
	public onNext(param: any): void {
		const req: SetMypInstallTerminalRequest = new SetMypInstallTerminalRequest();
		req.customer_id = this.m_MypageWizardMngr.getParams().customerId;
		req.terminal_list = new Array();
		const terminal : TerminalList = new TerminalList();
		terminal.terminal_id = this.m_TerminalListModel.terminal_id;
		terminal.stock_type_cd = this.m_TerminalListModel.stock_type_cd;
		terminal.terminal_type_cd = this.m_TerminalListModel.terminal_type_cd;
		req.terminal_list.push(terminal);
		this.m_TerminalService.setMypInstallTerminal(this, req, (result) => {
			if(result) {
				if(result.app_result_cd) {
					if (result.app_result_cd !== MypageErrorConstants.INSTALL_TERMINAL_API_ERROR && result.app_result_cd !== MypageErrorConstants.TERMINAL_FINISHED_ERROR) {
						this.createInquiry(result.app_result_cd);
					}
					switch (result.app_result_cd) {
						// 正常終了
						case MypageErrorConstants.TERMINAL_SUCCESS:
							this.pushPath('/terminal_regist_complete');
							break;
						// -400エラーの場合
						case MypageErrorConstants.TERMINAL_ERROR400:
							this.pushPath('/terminal_regist_complete');
							break;
						// それ以外のエラーの場合
						default:
							this.pushPath('/error?errorCd=' + result.app_result_cd);
							break;
					}
				} this.pushPath('/error');
			}
		});
	}

	/**
	 * <NavigateBeforeIcon />         戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}

	/**
	 * メール・お問い合わせ送信
	 */
	public createInquiry(cd: string): void {
		const req: RegistInfoRequest = new RegistInfoRequest();
		req.customerId = this.getCustomerId();
		req.systemInfo = new InquiryMailInfo();
		req.systemInfo.errorCd = cd;
		if (cd === MypageErrorConstants.TERMINAL_SUCCESS) {
			// 正常終了
			const mailconstants = MAIL_CONSTANTS.find (mail => {
				return mail.view === '端末登録_正常終了';
			});
			// お問い合わせ
			req.inquiryId = mailconstants.inquiryMail;
			// お客様メール
			req.customerMailId = mailconstants.customerMail;
			// エスカレーション
			req.systemInfo.escalationCd = 'SKID338';
		} else {
			// エラー終了
			const mailconstants = MAIL_CONSTANTS.find (mail => {
				return mail.view === '端末登録_エラー';
			});
			// お問い合わせ
			// エスカ先…SKID338：サポートリモコン送付用 SKID072：新規変更申込書作成 SKID108：申込書T/営業発注
			req.inquiryId = mailconstants.inquiryMail;
			switch (cd) {
				case MypageErrorConstants.TERMINAL_ERROR101:
					req.systemInfo.error101 = true;
					req.systemInfo.escalationCd = 'SKID108';
					break;
				case MypageErrorConstants.TERMINAL_ERROR102:
					req.systemInfo.error102 = true;
					req.systemInfo.escalationCd = 'SKID072';
					break;
				case MypageErrorConstants.TERMINAL_ERROR103:
					req.systemInfo.error103 = true;
					req.systemInfo.escalationCd = 'SKID108';
					break;
				case MypageErrorConstants.TERMINAL_ERROR104:
					req.systemInfo.escalationCd = 'SKID108';
					req.systemInfo.error104 = true;
					break;
				case MypageErrorConstants.TERMINAL_ERROR105:
					req.systemInfo.error105= true;
					req.systemInfo.escalationCd = 'SKID072';
					break;
				case MypageErrorConstants.TERMINAL_ERROR106:
					req.systemInfo.error106 = true;
					req.systemInfo.escalationCd = 'SKID338';
					break;
				case MypageErrorConstants.TERMINAL_ERROR200:
					req.systemInfo.error200 = true;
					req.systemInfo.escalationCd = 'SKID338';
					break;
				case MypageErrorConstants.TERMINAL_ERROR300:
					req.systemInfo.error300 = true;
					req.systemInfo.escalationCd = 'SKID338';
					break;
				case MypageErrorConstants.TERMINAL_ERROR400:
					// お客様メール
					req.customerMailId = mailconstants.customerMail;
					req.systemInfo.error400 = true;
					req.systemInfo.escalationCd = 'SKID338';
					break;
				default:
					break;
			}
		}
		// エスカレーション
		req.systemInfo.escalationFlg = true;
		this.m_CommonService.registInfo(this, req, (result) => {
			
		});
	}
	// --------------------------------------------------------------------------
}
