import { WprListData } from "../../../../../wpr-framework/view/deco/WprListData";
import { WprDI_ScopeModel } from "../../../../../wpr-framework/view/di/WprDI_ScopeModel";
import MypageDispdivcdConstants from "../../../../common/MypageDispdivcdConstants";
import { ObjectUtil } from "../../../../common/util/ObjectUtil";
import { Contract } from "../../../../models/bss/customer/Contract";
import { Customer } from "../../../../models/bss/customer/Customer";
import { ItscomNetService } from "../../../../service/ItscomNetService";
import { ServiceModel } from "../../../../service/models/apimodels/ServiceModel";
import { GetNetInfoRequest } from "../../../../service/models/dto/mypagerenewal/GetNetInfoRequest";
import { MCommon } from "../../../../service/models/entity/primary/MCommon";
import { MDisplay } from "../../../../service/models/entity/secondary/MDisplay";
import { MypageBaseView } from "../../../base/MypageBaseView";
import { ProgressBarData } from "../../../common/progress_bar/ProgressBarView";
import { MypageWizardMngr } from "../../../wizard/models/MypageWizardMngr";
import { WprViewMode } from '../../../../../wpr-framework/view/deco/WprViewMode';
import { CourseListMode } from './mode/CourseListMode';
import { NetServiceNewWizardModel } from "../../../wizard/NetServiceNewWizardFlow";
import { CourseListModel } from "../../../../models/mypage/net/CourseListModel";
import { NetFlowModel } from "../../../../models/mypage/net/NetFlowModel";
import { MGeneral } from "../../../../service/models/entity/MGeneral";
import MypageCodeTypeConstants from "../../../../common/MypageCodeTypeConstants";

/**
 * [サービス追加（変更）申込]コース内容の選択
 */
export class AddNetSelectView extends MypageBaseView {
	
	// コンストラクタ  -----------------------------------------------------------
	public constructor() { super('AddNetSelectView'); }
	// --------------------------------------------------------------------------

	// モデル  ------------------------------------------------------------------
	@WprDI_ScopeModel('MypageWizardMngr')
	private m_MypageWizardMngr: MypageWizardMngr			= null;		// 画面ウィザード管理クラス
	@WprListData('CourseListModel')
	private m_CourseListModel: CourseListModel[]			= null;		// コースリスト
	// --------------------------------------------------------------------------

	// サービス  ----------------------------------------------------------------
	private m_ItscomNetService: ItscomNetService	= new ItscomNetService();	// ItscomNetサービス
	// --------------------------------------------------------------------------

	// サブビュー/モード  --------------------------------------------------------
	@WprViewMode
	private m_CourseListMode = new CourseListMode();	// コースリストモード
	// --------------------------------------------------------------------------
	
	// メンバ変数  ---------------------------------------------------------------
	private m_Customer: Customer 							= null;		// 顧客情報
	private m_OwnerContract: Contract[]						= null;		// オーナー契約情報
	private m_CourseService: ServiceModel[]					= null;		// コース情報
	private m_BuildingPlanCode: MCommon						= null;		// 物件プランコード
	private m_LineType: MDisplay							= null;		// 回線種別
	private m_TanakoList: CourseListModel[]					= null;		// 店子コースリスト
	private m_TanakoCouseNameList: MDisplay[]				= null;		// 店子コース名称リスト
	private m_CoursePlus: MCommon							= null;		// かっとびプラス
	private m_CourseJust: MCommon							= null;		// かっとびジャスト
	private m_NetServiceNewModel: NetServiceNewWizardModel  = null;		// ネット追加モデル
	private m_NetFlowModel: NetFlowModel					= null;		// ネット遷移情報
	// --------------------------------------------------------------------------

	// プロパティ  ---------------------------------------------------------------
	/** ウィザード定義 */
	public get mngr(): MypageWizardMngr 					{ return this.m_MypageWizardMngr; }
	// --------------------------------------------------------------------------

	// override メソッド  --------------------------------------------------------
	/**
	 * コントロール定義設定
	 */
	public onRegisterConfig(): void {
		this.addListConfig('cDispnamep', 'コース名', 'CourseListModel');
		this.addListConfig('nDispnamep', '回線種別', 'CourseListModel');
		this.addListConfig('payAmount', '料金一覧', 'CourseListModel', { converter: '3桁カンマ' });
		this.addListConfig('payUnit', '料金単位', 'CourseListModel');
		this.addListConfig('check', '選択', 'CourseListModel');
	}
	
	/**
	* アクション登録
	 */
	public onRegisterAction(): void {
		this.addAction('next', this.onNext);
		this.addAction('back', this.onBack);
		this.addAction('other', this.onOther);
	}

	/**
	 * ビュー表示通知
	 */
	public onShowView(): void {
		this.m_MypageWizardMngr.initView(this, (result) => {
			this.registAccessLog();
			this.setStorageItem('b_url', window.location.href);
			const sendData: ProgressBarData = new ProgressBarData();
			sendData.progressBarList = this.m_MypageWizardMngr.getProgressModelList(this);
			this.sendChildData('fP_ev_progressBarIntital', sendData);
			// 遷移情報
			this.m_NetServiceNewModel = new NetServiceNewWizardModel();
			this.m_NetFlowModel = this.m_MypageWizardMngr.getCache().m_NetFlowModel;
			// 申し込み情報のクリア
			// this.m_MypageWizardMngr.getCache().m_OtherCourseListModel = new Array();
			// this.m_MypageWizardMngr.getCache().m_ConfirmListModel = new Array();
			// this.m_MypageWizardMngr.getCache().m_NetInfo = new CourseListModel();
			// 顧客情報
			this.m_Customer = this.m_MypageWizardMngr.getViewInfo().customer;
			// オーナー契約情報
			this.m_OwnerContract = this.m_MypageWizardMngr.getViewInfo().ownerContractList;
			// 物件プラン
			this.m_BuildingPlanCode = this.m_MypageWizardMngr.getBuildingPlan(this, this.m_OwnerContract);
			this.checkFtth();			// FTTH項目セット
			// コースリスト
			this.m_NetServiceNewModel.setCourseDisplay(this);
			this.setCourseList();
			this.refreshView();
		});
	}

	/**
	 * リスト値変更通知
	 * @param name 名前
	 * @param listName リスト名
	 * @param row 行データ
	 * @param value 値
	 * @returns falseの場合、変更を取り消す
	 */
	public onChangeListValue(name: string, listName: string, row: any, value: any): boolean {
		if (name === 'check') {
			// check全て外す
			for (const list of this.m_CourseListModel)
				list.check = false;
			row.check = true;
			this.refreshListView();
		}
		return true;
	}
	// --------------------------------------------------------------------------

	// アクション  ---------------------------------------------------------------
	/**
	 * 確認
	 */
	public onNext(param: any): void {
		this.refreshModel();
		this.m_NetFlowModel.otherFlg = false;
		this.m_NetFlowModel.kattobiFlg = false;
		this.m_NetFlowModel.defaultFlg = true;
		this.m_MypageWizardMngr.goNext(this);
	}

	/**
	 * 戻る
	 */
	public onBack(param: any): void {
		this.m_MypageWizardMngr.goBack(this);
	}

	/**
	 * その他のコースはこちら
	 */
	public onOther(param: any): void {
		this.m_NetFlowModel.defaultFlg = false;
		this.m_NetFlowModel.otherFlg = true;
		this.refreshModel();
		this.m_MypageWizardMngr.goNext(this);
	}
	// --------------------------------------------------------------------------

	// privateメソッド ---------------------------------------------------------
	/**
	 * FTTH判定
	 */
	private checkFtth() {
		// FTTHの場合
		if (this.m_Customer.building[0].buildingLineTypeCD === '2') {
			this.setViewMode('コース変更', 'FTTH');
			this.setViewMode('コース一覧', 'FTTH');
		}
		// FTTH以外の場合
		else {
			this.setViewMode('コース変更', 'FTTH以外');
			this.setViewMode('コース一覧', 'FTTH以外');
			this.setViewMode('その他コースエリア', 'FTTH以外');
		}
	}

	/**
	 * コースリスト設定
	 */
	private setCourseList() {
		this.m_CourseListModel = this.m_MypageWizardMngr.getCache().m_CourseListModel;
		const areaFig: boolean = this.m_MypageWizardMngr.getViewInfo().inAreaFlg;
		const netServiceList = this.m_MypageWizardMngr.getMGenralList(this, MypageCodeTypeConstants.NET_TEN_SERVICE, '');
		// 非表示とする1G対応サービス
		const excludeServiceList: string[] = this.m_MypageWizardMngr.getExcludeService(this);
		if (ObjectUtil.isNullOrUndefined(this.m_CourseListModel) || this.m_CourseListModel.length === 0) {
			const req: GetNetInfoRequest = new GetNetInfoRequest();
			req.customerId = this.m_MypageWizardMngr.getParams().customerId;
			req.ownerList = this.m_OwnerContract;
			req.buildingLineTypeCD = this.m_Customer.building[0].buildingLineTypeCD;
			req.buildingTypeCD = this.m_Customer.building[0].buildingTypeCD;
			this.m_ItscomNetService.getOwnerList(this, req, (result) => {
				if (result) {
					const lineList = this.m_MypageWizardMngr.getMDisplayList(this, MypageDispdivcdConstants.ITSCOMNET_LINETYPE, '');
					loop: for (const course of result.tanakoModel.tanakoList) {
						// 10Gエリア外非表示制御
						for (const netSevice of netServiceList) {
							if (!areaFig && netSevice.generalCd === course.tanakoServiceCd) {
								continue loop;
							}
						}
						// MDU1Gサービス非表示制御
						if (ObjectUtil.isNotNullOrEmptyArray(excludeServiceList)) {
							for (const exclude of excludeServiceList) {
								if (exclude === course.tanakoServiceCd) {
									continue loop;
								}
							}
						}
						const model = new CourseListModel();
						model.cDispnamep = course.serviceDispName;
						const amount = result.tanakoModel.serviceList.filter(data => data.serviceCD === course.tanakoServiceCd);
						if (amount && amount.length > 0) {
							if (amount[0].payment[0].amount === 0)
								model.payAmount = '-';
							else {
								model.payAmount = this.m_MypageWizardMngr.getTaxFee(this, String(amount[0].payment[0].amount));
								model.payUnit = '円';
							}
						}
						if (course.ftthFlg)
							model.nDispnamep = lineList.filter(data => data.namecd === '20')[0].dispnamep;
						else
							model.nDispnamep = lineList.filter(data => data.namecd === '10')[0].dispnamep;
						model.serviceCd = course.normalServiceCd;		// 通常コード(約款判定で使用)
						model.tanakoServiceCd = course.tanakoServiceCd; // 店子コード(料金シミュで使用)
						model.tanakoOwnerCd = course.ownerServiceCd;
						this.m_CourseListModel.push(model);
					}
				}
				// デフォルトチェック
				if (this.m_CourseListModel.length > 0)
					this.m_CourseListModel[0].check = true;
				else
					this.setViewMode('データなし', '表示');
				this.setListData('CourseListModel', this.m_CourseListModel);
			});
		}
		else {
			this.setListData('CourseListModel', this.m_CourseListModel);
		}
	}

	// --------------------------------------------------------------------------
}
